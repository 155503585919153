module.exports = {
  moduleName: 'ramsNodes',
  columns: [
    {
      field: 'failure.operator.name',
      label: 'operators.ref.name',
      visible: true
    },
    {
      field: 'failure.vehicle.vehicleType.name',
      label: 'vehicleTypes.ref.name',
      visible: true
    },
    {
      field: 'failure.vehicle.number',
      label: 'vehicles.ref.number',
      visible: true
    },
    {
      field: 'part.supplier.name',
      label: 'suppliers.ref.name',
      visible: true
    },
    {
      field: 'part.name',
      label: 'parts.ref.name',
      visible: true
    },
    {
      field: 'number',
      label: 'common.number',
      visible: true
    },
    {
      field: 'failure.progress.reported.at',
      label: 'ramsNodes.props.failure.progress.reported.at',
      visible: true,
      timestamp: true
    },
    {
      field: 'operating_since',
      label: 'ramsNodes.props.operating_since',
      visible: true,
      timestamp: true
    },
    {
      field: 'daysInOperation',
      label: 'ramsNodes.props.daysInOperation',
      visible: true
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {
    part: null,
    state: [],
    number: null,
    number_array: null
  },
  orderBy: 'created_at,desc',
  orderByOptions: {
    'created_at,desc': 'Last created'
  }
}