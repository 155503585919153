export default {
  props: {
    moduleName: {
      type: String,
      required: false
    }
  },
  computed: {
    module () {
      return this.manualDoc ? this.manualDoc.module : (this.moduleName || this.currentModuleName)
    }
  }
}
