<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
    border-radius: 50%;
  }
</style>

<template>
  <div class="floating-button-wrapper">
    <a
      v-if="canCreate"
      class="button floating-button is-success is-large"
      :title="$t('components.addDocButton.title', { documentName: computedModuleName})"
      @click="create"
    >
      <b-icon icon="plus" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import addDocMixin from '@/components/mixins/addDocMixin'

export default {
  mixins: [addDocMixin]
}
</script>
