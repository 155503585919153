const numberFormats = {
  'cs': {
    currency: {
      style: 'currency', currency: 'CZK'
    }
  },
  'en': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'de': {
    currency: {
      style: 'currency', currency: 'EUR'
    }
  },
  'ch': {
    currency: {
      style: 'currency', currency: 'CHF'
    }
  },
}

module.exports = {
  numberFormats
}
