import { mapGetters } from 'vuex'
import manualModule from '@/components/mixins/manualModule'

export default {
  mixins: [manualModule],
  props: {
    docProps: {
      type: Object,
      default: {}
    }
  },
  computed: {
    computedModuleName () {
      return _.trimEnd(this.module, 's')
    },
    canCreate () {
      return this.$store.getters[`${this.module}/canCreate`]
    }
  },
  methods: {
    async create () {
      const newDoc = _.assign({}, this.docProps)
      const id = await this.$store.dispatch(`${this.module}/insert`, newDoc)
      this.$router.push({ name: this.computedModuleName, params: { id } })
    }
  }
}
