module.exports = {
  progress: {
    repaired: {
      at: '%convertTimestamp%'
    },
    completed: {
      at: '%convertTimestamp%'
    }
  },
  created_at: '%convertTimestamp%',
  updated_at: '%convertTimestamp%'
}