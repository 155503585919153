const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'failureSupplies',
  moduleName: 'failureSupplies',
  state: {
    moduleName: 'failureSupplies',
    props,
    list: {},
    form: {}
  },
  actions: {
    belongsToCustomer ({ dispatch, rootGetters }) {
      dispatch('mergeWhere', ['failure.customer.id', '==', rootGetters['user/customerId']])
    },
    async remove ({ dispatch, state, getters, rootState, rootGetters }, id) {
      const doc = { failureSupply: null }
      await dispatch(`failureParts/fetchAndAdd`, { where: [
        [`failureSupply.id`, '==', id]
      ], limit: 0 }, { root: true })
      const ids = _.map(_.filter(rootGetters[`failureParts/items`], item => _.get(item, `failureSupply.id`) === id), 'id')

      await dispatch(`failureParts/patchBatch`, { doc, ids }, { root: true})
      await dispatch(`delete`, id)
    }
  },
  getters: {
    currentSupplyId: (state, getters, rootState, rootGetters) => {
      const { route } = rootState
      if (route.name === 'failureSupplie') {
        let failureSupply = rootGetters['failureSupplies/items'].find(item => item.id === route.params.id)
        return failureSupply ? failureSupply.id : null
      } else {
        let related = rootGetters[`${route.name}s/items`].find(item => item.id === route.params.id)
        return (related && related.failureSupply) ? related.failureSupply.id : null
      }
      return null
    },
    currentSupplyFailure: (state, getters, rootState, rootGetters) => {
      const currentFailureId = rootGetters['failures/currentFailureId']
      if (currentFailureId) {
        return rootGetters['failures/items'].find(item => item.id === currentFailureId)
      }
      return null
    },
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || isReader || isTechnician
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
