module.exports = {
  moduleName: 'failureParts',
  columns: [
    {
      field: 'part.name',
      label: 'parts.ref.name',
      visible: true
    },
    {
      field: 'number',
      label: 'common.number',
      visible: true
    },
    {
      field: 'state',
      label: 'common.state',
      visible: true,
      customComponent: true
    },
    {
      field: 'name',
      label: 'common.description',
      visible: true
    },
    {
      field: 'replacement.part.name',
      label: 'failureParts.columns.replacement.part.name',
      visible: true
    },
    {
      field: 'replacement.number',
      label: 'failureParts.columns.replacement.number',
      visible: true
    },
    {
      field: 'failure.customer.name',
      label: 'customers.ref.name',
      visible: true
    },
    {
      field: 'failure.operator.name',
      label: 'operators.ref.name',
      visible: true
    },
    {
      field: 'position',
      label: 'failureParts.props.position',
      visible: true
    },
    {
      field: 'failure.type',
      label: 'failures.ref.type',
      visible: false
    },
    {
      field: 'failure.recognized',
      label: 'failures.props.recognized',
      visible: false
    },
    {
      field: 'failure.location',
      label: 'failures.props.location',
      visible: false
    },
    {
      field: 'failure.progress.reported.at',
      label: 'failures.props.progress.reported.at',
      visible: false,
      timestamp: true
    },
    {
      field: 'solution',
      label: 'failureParts.props.solution',
      visible: false
    },
    {
      field: 'reason',
      label: 'failureParts.props.reason',
      visible: false
    },
    {
      field: 'part.supplier.name',
      label: 'suppliers.ref.name',
      visible: false
    },
    {
      field: 'failure.number',
      label: 'failures.ref.number',
      visible: false
    },
    {
      field: 'failure.name',
      label: 'failures.ref.name',
      visible: false
    },
    {
      field: 'parent.part.name',
      label: 'failureParts.columns.parent.part.name',
      visible: false
    },
    {
      field: 'parent.number',
      label: 'failureParts.columns.parent.number',
      visible: false
    },
    {
      field: 'failureSupply.number',
      label: 'failureSupplies.ref.number',
      visible: false
    },
    {
      field: 'progress.repaired.at',
      label: 'failureParts.props.progress.repaired.at',
      visible: false,
      timestamp: true
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {
    failure: {
      operator: null,
      type: null,
      progress: {
        reported: {
          at_gte: null
        }
      }
    },
    part: {
      supplier: null,
      id: null
    },
    state: [],
    number: null,
    number_array: null
  },
  orderBy: 'failure.progress.reported.at',
  orderOptions: {
    'failure.progress.reported.at': 'Reported at'
  }
}
