<template>
  <span
    v-if="computedValue"
    v-bind="$attrs"
  >
    <i class="has-text-weight-medium">{{ computedLabel }}:&nbsp;</i>
    <slot>{{ computedValue }}</slot>
    <br>
  </span>
<!--
    <span v-html="doc.odometer ? `<i>Odometer:</i> ${doc.odometer} km<br/>` : ''"></span>

          <span :class="[{ 'has-text-danger': doc.type == 'guarantee' }]  ">
            {{ doc.type }}
          </span><br/>

          <span :class="rowClass" v-html="doc.state ? `<i>State:</i> ${doc.state}<br/>` : ''"></span> -->
</template>

<script>
import _ from 'lodash'
import { findPropertyInVueTree, findPropertyInCollection } from '@/helpers'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    valueFormatter: {
      type: Function,
      required: false
    }
  },
  computed: {
    foundObjectModule() {
      return findPropertyInVueTree(this, 'objectModule')
    },
    computedModule() {
      const computedModule = this.foundObjectModule || this.module
      // console.log({ computedModule })
      return computedModule
    },
    fields() {
      return this.$store.getters[`${this.computedModule}/fields`]
    },
    computedField() {
      const computedField = findPropertyInCollection(this.fields, this.field)
      // console.log(this.field, { computedField })
      return computedField
    },
    computedLabel() {
      return this.label || this.$t(this.computedField.label || `${this.computedModule}.props.${this.computedField.field}`)
    },
    computedDoc() {
      return findPropertyInVueTree(this, 'objectDoc') || this.doc
    },
    rawValue() {
      return _.get(this.computedDoc, this.field) || ''
    },
    computedValue() {
      if(this.computedField && this.computedField.options && _.includes(this.computedField.options, this.rawValue)) {
        return this.$t(`${this.computedModule}.options.${this.field}.${_.snakeCase(this.rawValue)}`)
      }
      if(this.valueFormatter && this.rawValue) {
        return this.valueFormatter(this.rawValue)
      }
      return this.rawValue
    }
  },
  created() {
  }
}
</script>
