<template>
  <b-dropdown
    :value="locale"
    aria-role="list"
    @change="changeLocale"
  >
    <button
      slot="trigger"
      class="button is-primary"
      type="button"
    >
      <template>
        <b-icon icon="earth" />
        <span>{{ locale }}</span>
      </template>

      <b-icon icon="menu-down" />
    </button>

    <b-dropdown-item
      v-for="locale in locales"
      :key="locale"
      :value="locale"
      aria-role="listitem"
    >
      <div class="media">
        <b-icon
          class="media-left"
          icon="earth"
        />
        <div class="media-content">
          <h3>{{ locale }}</h3>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

const locales = [
  'en',
  'cs',
  'de'
]

export default {
  data() {
    return {
      locales
    }
  },
  computed: {
    ...mapGetters({
      locale: 'user/locale'
    })
  },
  methods: {
    async changeLocale(value) {
      this.$root.$i18n.locale = value
      await this.$store.dispatch("user/update", {
        path: 'locale',
        value
      })
      await this.$store.dispatch('locales/openDBChannel', { where: [['id', '==', value]] })
    }
  }
}
</script>

