module.exports = {
  data: [],
  props: [],
  selectedItemsCollection: 'items',
  fetchIdentifier: null,
  fetchingDone: {},
  sync: {
    where: [],
    orderBy: []
  },
  rules: {
    read: []
  },
  list: {
    components: {},
    props: {},
    search: '',
    orderBy: 'created_at,desc',
    orderOptions: {},
    initialFilters: {},
    tooltips: {}
  },
  form: {
    tooltips: {}
  }
}


