import firebase from 'firebase'
import firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export default function login () {
  let self = this
  let uiConfig = {
    signInOptions: [{
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    }
    ],
    callbacks: {
      signInSuccessWithAuthResult () {
        window.location.href = '/'
      }
    }
  }
  var ui = new firebaseui.auth.AuthUI(firebase.auth())
  ui.start('#app', uiConfig)
}
