<template>
  <b-sidebar
    ref="menu"
    v-model="sidebar"
    position="static"
    :reduce="true"
    :expand-on-hover="true"
  >
    <b-menu class="is-custom-mobile">
      <b-menu-list>
        <b-menu-item
          icon="history"
          :label="$t('layout.sidebar.recent_failures')"
        >
          <b-menu-item
            v-for="(failure, index) in recentFailures"
            :key="failure.id"
            :icon="`numeric-${index+1}-box-outline`"
            :to="{ name: 'failure', params: { id: failure.id }}"
            tag="router-link"
          >
            <template #label>
              <span>
                {{ failure.name }}<br>
                <b-tag
                  type="is-primary is-light"
                >
                  {{ failure.operator ? failure.operator.name : '' }}
                </b-tag>
              </span>
            </template>
          </b-menu-item>
        </b-menu-item>
        <b-menu-item
          icon="account"
          :label="$t('layout.sidebar.tools')"
        >
          <router-view name="tools" />
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </b-sidebar>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      sidebar: 'user/sidebar',
      recentFailures: 'user/recentFailures'
    })
  },
  methods: {
    ...mapActions({
      switchAppMode: 'user/switchAppMode',
      logout: 'user/logout'
    })
  }
}
</script>

<style lang="scss">
.p-1 {
  padding: 1em;
}
.sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    // min-height: 100vh;
    .sidebar-layout {
        display: flex;
        flex-direction: row;
        min-height: 100%;
        // min-height: 100vh;
    }
}
@media screen and (max-width: 1023px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini-mobile {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                    .field {
                      span:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .b-sidebar {
        .sidebar-content {
            &.is-mini {
                &:not(.is-mini-expand),
                &.is-mini-expand:not(:hover) {
                    .menu-list {
                        li {
                            a {
                                span:nth-child(2) {
                                    display: none;
                                }
                            }
                            ul {
                                padding-left: 0;
                                li {
                                    a {
                                        display: inline-block;
                                    }
                                }
                            }
                        }
                        .field {
                          span:nth-child(2) {
                                display: none;
                            }
                        }
                    }
                    .menu-label:not(:last-child) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
</style>

<!-- display: flow-root;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden -->
