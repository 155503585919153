const { state } = require('./options')

module.exports = [
  { field: 'part', label: 'parts.sg', default: {}, options: 'parts' },
  { field: 'state', label: 'common.state', options: state, default: 'received' },
  { field: 'number', label: 'common.number' },
  { field: 'number_array', label: false },
  { field: 'name', label: 'common.name' },
  { field: 'resolution', label: 'common.resolution' },
  { field: 'replacement.number' },
  { field: 'progress.repaired.at', timestamp: true },
  { field: 'progress.repaired.by' },
  { field: 'progress.completed.at', timestamp: true },
  { field: 'progress.completed.by' }

]
