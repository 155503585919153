import { mapGetters, mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoUpdate: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value () {
      return _.get(this.doc, this.field)
    },
    fieldType () {
      return (this.required && !this.value) ? 'is-warning' : 'is-default'
    }
  },
  methods: {
    update (newValue) {
      if (this.autoUpdate) {
        let value = _.clone(newValue)
        if (_.isPlainObject && this.toPick) {
          value = _.pick(newValue, this.toPick)
        }

        let newObj = _.set({}, this.field, value)
        if (!this.manualDoc) {
          this.$store.dispatch(`currentDoc/set`, {
            field: this.field,
            value
          })
        } else {
          const params = this.manualDoc.id ? { id: this.manualDoc.id } : {}
          this.$store.dispatch(`${this.manualDoc.module}/set`, _.assign({}, params, newObj))
        }
        this.$emit('change', value)
      }
    }
  }
}
