import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    options: {
      type: [String, Object, Array],
      default: null
    },
    labels: {
      type: Object,
      required: false
    },
    nullOption: {
      type: Boolean,
      default: false
    },
    optionKey: {
      type: String,
      default: 'id'
    },
    optionLabel: {
      type: [String, Function],
      default: 'name'
    },
    toPick: {
      type: Array,
      required: false
    }
  },
  computed: {
    fields () {
      if (!this.manualDoc) {
        return this.$store.getters['currentDoc/fields']
      } else {
        return this.$store.getters[`${this.manualDoc.module}/fields`]
      }
    },
    fieldOptions() {
      const fieldProps = _.get(this.fields, this.field)
      return fieldProps && fieldProps.options ? fieldProps.options : []
    },
    optionsArray (state, getters) {
      let options = []
      if (this.options === null) {
        options = this.fieldOptions
      } else if (typeof this.options === 'string') {
        options = _.clone(this.$store.getters[`${this.options}/items`])
      } else {
        options = this.options
      }
      if (this.nullOption) {
        if (!(_.includes(options, null))) {
          let item = null
          options.unshift(item)
        }
      }
      return options
    }
  },
  created () {
    if (typeof this.options === 'string') {
      this.$store.dispatch(`${this.options}/fetchAll`)
    }
  }
}
