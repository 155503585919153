module.exports = {
  moduleName: 'failures',
  columns: [
    {
      field: 'customer.name',
      label: 'customers.ref.name',
      visible: true
    },
    {
      field: 'operator.name',
      label: 'operators.ref.name',
      visible: true
    },
    {
      field: 'name',
      label: 'failures.props.name',
      visible: true
    },
    {
      field: 'type',
      label: 'failures.props.type',
      visible: true
    },
    {
      field: 'vehicle.number',
      label: 'vehicles.ref.number',
      visible: true
    },
    {
      field: 'vehicle.vehicleType.name',
      label: 'vehicleTypes.ref.name',
      visible: true
    },
    {
      field: 'progress.reported.at',
      label: 'failures.props.progress.reported.at',
      visible: true,
      timestamp: true
    },
    {
      field: 'number',
      label: 'common.number',
      visible: true
    },
    {
      field: 'state',
      label: 'common.state',
      visible: true
    },
    {
      field: 'recognized',
      label: 'failures.props.recognized',
      visible: false
    },
    {
      field: 'location',
      label: 'failures.props.location',
      visible: false
    },
    {
      field: 'odometer',
      label: 'failures.props.odometer',
      visible: false
    },
    {
      field: 'order_number',
      label: 'failures.props.order_number',
      visible: false
    },
    {
      field: 'service.travel_distance',
      label: 'failures.columns.service.travel_distance',
      visible: false
    },
    {
      field: 'service.travel_hours',
      label: 'failures.columns.service.travel_hours',
      visible: false
    },
    {
      field: 'service.work_hours',
      label: 'failures.columns.service.work_hours',
      visible: false
    },
    {
      field: 'vehicle.vehicleType.description',
      label: 'vehicleTypes.ref.description',
      visible: false
    },
    {
      field: 'progress.serviced.at',
      label: 'failures.props.progress.serviced.at',
      visible: false,
      timestamp: true
    },
    {
      field: 'progress.completed.at',
      label: 'failures.props.progress.completed.at',
      visible: false,
      timestamp: true
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {
    operator: null,
    vehicle: null,
    state: [
      'reported',
      'serviced'
    ],
    order_number: null,
    order_number_array: null,
    number: null,
    number_array: null
  },
  orderBy: 'created_at,desc'
}
