module.exports = {
  progress: {
    reported: {
      at: '%convertTimestamp%'
    },
    serviced: {
      at: '%convertTimestamp%'
    },
    completed: {
      at: '%convertTimestamp%'
    },
    estimated: {
      at: '%convertTimestamp%'
    }
  },
  created_at: '%convertTimestamp%',
  updated_at: '%convertTimestamp%'
}