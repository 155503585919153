import firebase from 'firebase'

var config = {
  apiKey: 'AIzaSyAfIlh3E_a1bhFF40yXfL4Vo3Wqf4Z0EkQ',
  authDomain: 'rams-e6d4f.firebaseapp.com',
  databaseURL: 'https://rams-e6d4f.firebaseio.com',
  projectId: 'rams-e6d4f',
  storageBucket: 'rams-e6d4f.appspot.com',
  messagingSenderId: '119852824087'
}

const db = firebase.initializeApp(config).database()

export default db
