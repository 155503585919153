<template>
  <div
    v-if="!fetchedAll"
    class="box has-text-centered"
  >
    <button
      class="button is-primary "
      @click="fetchMore"
    >
      {{ $t('components.moreDocsButton.more') }}
    </button>
    <button
      class="button is-default "
      @click="fetchRest"
    >
      {{ $t('components.moreDocsButton.all') }}
    </button>
  </div>
</template>

<script>

export default {
  computed: {
    fetchedAll () {
      return this.$store.getters[`${this.module}/fetchedAll`]
    },
    fetchIdentifier () {
      return this.$store.getters[`${this.module}/fetchIdentifier`]
    }
  },
  methods: {
    async fetchMore () {
      const result = await this.$store.dispatch(`${this.module}/fetchDocs`)
    },
    async fetchRest () {
      const result = await this.$store.dispatch(`${this.module}/fetchRest`)
    }
  }
}
</script>
