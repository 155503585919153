const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  moduleName: 'failureNodes',
  firestorePath: 'failureNodes',
  state: {
    moduleName: 'failureNodes',
    props,
    list: {
      initialFilters: {
        failure: {
          operator: null,
          type: null,
          progress: {
            reported: {
              at_gte: null
            }
          }
        },
        part: {
          supplier: null,
          id: null
        },
        state: [],
        number: null,
        number_array: null
      },
      orderBy: 'failure.progress.reported.at',
      orderOptions: {
        'failure.progress.reported.at': 'Reported at'
      }
    },
    form: {
      hideParts: false
    }
  },
  actions: {
    belongsToCustomer ({ dispatch, rootGetters }) {
      dispatch('mergeWhere', ['failure.customer.id', '==', rootGetters['user/customerId']])
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || isReader || isTechnician
    },
    canUpdateDocument: (state, getters, rootState, rootGetters) => (document) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || (isTechnician && document.state !== 'completed')
    },
    currentFailureItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const currentFailureId = rootGetters['failures/currentFailureId']
      if (items && currentFailureId) {
        return _.filter(items, item => item.failure.id === currentFailureId)
      }
      return null
    },
    currentFailureRootItems: (state, getters, rootState, rootGetters) => {
      const { currentFailureItems } = getters
      return _.filter(currentFailureItems, item => item.parent === null)
    },
    rootItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      return _.filter(items, item => item.parent === null)
    },
    rowClass: (state) => (row) => {
      switch (row.state) {
        case 'draft':
          return 'has-text-grey'
        case 'received':
          return 'has-background-warning'
        case 'repairing':
          return 'has-background-info'
        case 'repaired':
          return 'has-background-success'
        case 'completed':
          return 'has-background-grey-lighter has-text-grey'
        default:
          return ''
      }
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues,
    patchHook: function (updateStore, change, store) {
      if(change.state) {
        change.progress = {
          [change.state]: {
            at: moment().toDate(),
            by: store.getters["user/user"]
          }
        }

        if(_.includes(['repaired'], change.state)) {
          const doc = store.state.failureNodes.data[change.id]
          if(doc.parent === null && (!doc.name || !doc.resolution)) {
            store.dispatch("failureNodes/openRequiredForm", { change, updateStore })
          } else {
            return updateStore(change)
          }
        } else {
          return updateStore(change)
        }
      }
      return updateStore(change)
    }
  }
}
