<template>
  <div>
    <template v-if="tooltipsActive">
      <b-tooltip
        :label="text"
        :position="position"
        multilined
      >
        <slot />
      </b-tooltip>
    </template>
    <template v-else>
      <slot />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Array],
      default: null
    },
    position: {
      type: String,
      default: 'is-bottom'
    }
  },
  computed: {
    text () {
      return typeof this.label === 'string' ? this.label : this.label.join('\n\n')
    }
  }
}
</script>
