module.exports = [
  { field: 'name', label: 'common.name' },
  { field: 'operators', default: [], label: 'operators.pl' },
  { field: 'officialName', label: 'common.official_name' },
  { field: 'address', label: 'common.address' },
  { field: 'city', label: 'common.city' },
  { field: 'zip', label: 'common.zip' },
  { field: 'country', label: 'common.country' },
  { field: 'regNr', label: 'common.regNr' },
  { field: 'vatNr', label: 'common.vatNr' },
  { field: 'contacts', label: 'common.contacts' }
]
