const _ = require('lodash')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')


module.exports = {
  firestorePath: 'mails',
  moduleName: 'mails',
  state: {
    moduleName: 'mails',
    props: [],
    list: null,
    form: null
  },
  getters: {
    currentFailureItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const currentFailureId = rootGetters['failures/currentFailureId']
      if (items && currentFailureId) {
        return _.filter(items, item => item.failure.id === currentFailureId)
      }
      return null
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
