const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')
const { getFailureNumber } = require('../../callables')

module.exports = {
  firestorePath: 'failures',
  moduleName: 'failures',
  actions: {
    async setCompleted ({ dispatch, state, getters, rootState, rootGetters }, payload) {
      const { children } = state
      const { id } = payload
      const doc = { state: 'completed' }

      await dispatch(`failureParts/fetchAndAdd`, { where: [
        [`failure.id`, '==', id]
      ] }, { root: true })

      await dispatch(`failureNodes/fetchAndAdd`, { where: [
        [`failure.id`, '==', id]
      ] }, { root: true })

      const failurePartsIds = _.map(_.filter(rootGetters[`failureParts/items`], item => _.get(item, `failure.id`) === id), 'id')
      const failureNodesIds = _.map(_.filter(rootGetters[`failureNodes/items`], item => _.get(item, `failure.id`) === id), 'id')

      await dispatch(`failureParts/patchBatch`, { doc, ids: failurePartsIds }, { root: true})
      await dispatch(`failureNodes/patchBatch`, { doc, ids: failureNodesIds }, { root: true})
      await dispatch(`set`, _.assign({ id }, doc))
    }
  },
  state: {
    moduleName: 'failures',
    props,
    rules: {
      read: ['belongsToCustomer']
    },
    children: ['failureParts', 'failureNodes', 'failureSupplies'],
    list: {
      initialFilters: {
        operator: null,
        vehicle: null,
        state: ['reported', 'serviced'],
        order_number: null,
        order_number_array: null,
        number: null,
        number_array: null
      }
    },
    form: {
      tooltips: {
        category: 'A - nejede \A B - a nepojede'
      },
      failureParts: {
        hideParts: false,
        hideHeaders: false
      },
      newNode: {
        hideParts: false
      }
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || isCustomer || isReader || isTechnician
    },
    canCreate: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || isCustomer || isTechnician
    },
    canUpdate: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      const documentState = rootGetters['currentDoc/currentDoc'] ? rootGetters['currentDoc/currentDoc'].state : null
      return isAdmin || isCustomer || (isTechnician && documentState !== 'completed')
    },
    /*displayedItems: (state, getters, rootState, rootGetters) => {
      const { routeSettings, displayedFields } = getters
      if (routeSettings) {
        const { isFiltering, search } = routeSettings
        const items = getters['filteredItems']
        let items = []
        if (isFiltering) {
          items = getters['filteredItems']
        } else {
          items = getters['watched']
        }
        if (displayedFields && displayedFields.length && items && items.length) {
          return helpers.searchInFields(search, displayedFields, items)
        }
      }
      return getters['filteredItems']
    },*/
    currentFailureId: (state, getters, rootState, rootGetters) => {
      const { route } = rootState
      if (route.name === 'failure') {
        let failure = rootGetters['failures/items'].find(item => item.id === route.params.id)
        return failure ? failure.id : null
      } else {
        let related = rootGetters[`${route.name}s/items`].find(item => item.id === route.params.id)
        return (related && related.failure) ? related.failure.id : null
      }
      return null
    },
    currentFailure: (state, getters, rootState, rootGetters) => {
      const { currentFailureId } = getters
      if(currentFailureId) {
        const { items } = getters
        const failure = _.find(items, { id: currentFailureId })
        return failure || null
      }
      return null
    },
    reported: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      return items.filter(item => item.state === 'reported')
    },
    reportedNotEstimated: (state, getters, rootState, rootGetters) => {
      const { reported } = getters
      return reported.filter(item => item.progress.estimated.at == null)
    },
    upcomingDeadline: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const upcomingLimit = rootGetters['user/routes'].dashboard.estimated.upcomingLimit
      return items.filter(item =>
        (upcomingLimit ? moment(item.progress.estimated.at).isBefore(moment().add(upcomingLimit, 'days').toDate(), 'day') : true)
        && _.includes(['reported'], item.state)
      )
    },
    title: (state, getters, rootState, rootGetters) => {
      const doc = rootGetters["currentDoc/currentDoc"]
      if(doc) {
        return (doc.operator ? doc.operator.name : '') +' '+ doc.number
      }
      return null
    },
    rowClass: (state) => (row) => {
      switch (row.state) {
        case 'draft':
          return 'has-text-grey'
        case 'reported':
          return 'has-background-info'
        case 'serviced':
          return 'has-background-success'
        case 'completed':
          return 'has-background-grey-lighter has-text-grey'
        default:
          return ''
      }
    },
    nodesHours: (state, getters, rootState, rootGetters) => {
      function nodesSum( type ) {
        return _.sumBy(rootGetters["failureNodes/currentFailureItems"], node => node.service && node.service[type] ? Number(node.service[type]) : 0)
      }

      return Number(nodesSum('inspection'))
        + Number(nodesSum('repair'))
        + Number(nodesSum('testing'))
    },
    extraHours: (state, getters, rootState, rootGetters) => {
      const { inspection, repair, testing } = rootGetters["currentDoc/currentDoc"].service
      return Number(inspection) + Number(repair) + Number(testing)
    },
    totalHours: (state, getters, rootState, rootGetters) => {
      const { nodesHours, extraHours } = getters
      return nodesHours + extraHours
    },
    hours: (state, getters, rootState, rootGetters) => type => {
      function nodesSum( type ) {
        return _.sumBy(rootGetters["failureNodes/currentFailureItems"], node => node.service && node.service[type] ? Number(node.service[type]) : 0)
      }

      return Number(rootGetters["currentDoc/currentDoc"].service[type]) + Number(nodesSum(type))
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
