const state = [
  'draft',
  'reported',
  'serviced',
  'completed'
]

const location = [
  'vehicle',
  'site',
  'cegelec'
]

const type = [
  'guarantee',
  'post guarantee'
]

const recognized = [
  'yes',
  'no'
]

module.exports = {
  state,
  location,
  type,
  recognized
}
