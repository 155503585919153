const state = [
  'received',
  'repairing',
  'repaired',
  'completed'
]

module.exports = {
  state
}
