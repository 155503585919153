module.exports = {
  moduleName: 'users',
  columns: [
    {
      field: 'name',
      label: 'users.props.name',
      visible: true
    },
    {
      field: 'email',
      label: 'common.email',
      visible: true
    },
    {
      field: 'role',
      label: 'users.props.role',
      visible: true
    },
    {
      field: 'customer.name',
      label: 'customers.ref.name',
      visible: true
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {},
  orderBy: 'name,asc'
}