<template>
  <section>
    <b-table
      :data="data"
      checkable
      narrowed
      :row-class="() => 'has-background-grey-lighter'"
      :checked-rows="checked"
      draggable
      @check="updateChecked"
      @check-all="updateAllChecked"
      @dragstart="dragstart"
      @drop="drop"
      @dragover="dragover"
      @dragleave="dragleave"
    >
      <b-table-column
        v-slot="props"
        field="label"
        :label="$t('common.column')"
      >
        <span class="is-bold">{{ $t(props.row.label) }}</span>
        <br>
        <span>{{ false ? `${props.row.field} - ${props.row.label}` : '' }}</span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import move from 'lodash-move'

export default {
  props: {
    userSettingsKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      draggingRow: null,
      draggingRowIndex: null
    }
  },
  computed: {
    ...mapState({
      data: function (state) {
        return _.get(state.user.data.routes, this.userSettingsKey)
      }
    }),
    checked () {
      return _.filter(this.data, { visible: true })
    }
  },
  methods: {
    updateChecked (arr, row) {
      row.visible = !row.visible
      this.updateUserSettings(this.data)
    },
    updateAllChecked (arr) {
      _.forEach(this.data, function (row) {
        arr.length ? row.visible = true : row.visible = false
      })
      this.updateUserSettings(this.data)
    },
    // row.visible = !row.visible
    // this.updateUserSettings(this.data)
    updateUserSettings (value) {
      /* if (!value) {
        console.log('assigning this.data to payload.value')
        value = this.data
      }
      console.log('updating value', value) */
      this.$store.dispatch('user/update', {
        path: `routes.${this.userSettingsKey}`,
        value
      })
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index
      let newData = this.data
      // let newData = _.clone(this.data)
      newData = move(newData, this.draggingRowIndex, payload.index)
      this.updateUserSettings(newData)
      // this.$toast.open(`Moved ${this.draggingRow.first_name} from row ${this.draggingRowIndex + 1} to ${droppedOnRowIndex + 1}`)
    }
  }
}
/* created () {
    this.data = _.clone(this.userData)
  } */
</script>
