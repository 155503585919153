const _ = require('lodash')

const makeFailureRef = function (failure) {
  return _.assign({}, _.omit(failure, [
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
    'notes',
    'contacts',
    'team',
    'order_number_array',
    'number_array'

  ]), {
    customer: _.pick(failure.customer, ['id', 'name']),
    operator: _.pick(failure.operator, ['id', 'name']),
    vehicle: failure.vehicle ? _.pick(failure.vehicle, ['id', 'number', 'vehicleType.id', 'vehicleType.name']) : null
  })
}

module.exports = makeFailureRef
