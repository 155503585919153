const _ = require('lodash')
const Vue = require('vue')
const helpers = require('../helpers')

// import { modules } from '@/store'

module.exports = {
  moduleName: 'currentDoc',
  namespaced: true,
  actions: {
    set ({ dispatch, rootState, commit, state, getters }, payload) {
      let newObj = _.set({}, payload.field, payload.value)
      dispatch(`${getters['currentModuleName']}/set`, _.assign({}, { id: getters.docId }, newObj), { root: true })
    },
    async loadDoc ({ dispatch, getters, rootState }, payload) {
      const { currentModuleName, docId } = getters
      // console.warn({ currentModuleName, docId })
      // console.warn('doc in loading', rootState[currentModuleName].data[docId])
      return new Promise(async (resolve, reject) => {
        if (!_.has(rootState[currentModuleName].data, docId)) {
          const result = await dispatch(`${currentModuleName}/openDBChannel`, { where: [
            ['id', '==', docId]
          ] }, { root: true })
          const streamingResult = result.streaming
          console.log({ streamingResult })
          await streamingResult.resolve()
        }
        resolve(rootState[currentModuleName].data[docId])
      })
    },
    async loadChildren ({ dispatch, rootState }, payload) {
      const { route } = rootState
      await dispatch(`${payload.module}/fetchAndAdd`, { where: [
        [payload.ref, '==', payload.id || route.params.id]
      ], limit: 0 }, { root: true })
    },
    async deleteWithChildren ({ dispatch, getters, rootState, rootGetters }) {
      const { currentModuleName, docId } = getters
      const moduleName = _.trimEnd(currentModuleName, 's')
      const { children } = rootState[currentModuleName]
      _.forEach(children, async child => {
        await dispatch(`${child}/fetchAndAdd`, { where: [
          [`${moduleName}.id`, '==', docId]
        ] }, { root: true })
        const ids = _.map(_.filter(rootGetters[`${child}/items`], item => _.get(item, `${moduleName}.id`) === docId), 'id')
        await dispatch(`${child}/deleteBatch`, ids, { root: true})
        console.log(child, ids)
      })
      await dispatch(`${currentModuleName}/delete`, docId, { root: true })
    }
  },
  state: {
    disabled: true
  },
  getters: {
    currentModuleName: (state, getters, rootState) => {
      const { route } = rootState
      const { docId } = getters
      if (route.name && docId) {
        return route.name + 's'
      }
      return route.name || null
    },
    currentModule: (state, getters, rootState) => {
      return null
      const { currentModuleName } = getters
      return currentModuleName ? rootState[currentModuleName] : null
    },
    docsCount: (state, getters, rootState) => {
      return null
      const { currentModule } = getters
      return currentModule && currentModule.data ? currentModule.data.length : null
    },
    docId: (state, getters, rootState) => {
      const { route } = rootState
      return (route && route.params.id) ? route.params.id : null
    },
    currentDoc: (state, getters, rootState) => {
      const { currentModuleName, docId } = getters
      if (currentModuleName && docId) {
        return rootState[currentModuleName] ? rootState[currentModuleName].data[docId] : null
      }
      return null
    },
    filesPath: (state, getters, rootState) => {
      const { currentModuleName, docId } = getters
      if (currentModuleName && docId) {
        return `${currentModuleName}/${docId}`
      }
      return null
    },
    canRead: (state, getters, rootState, rootGetters) => {
      const { currentModuleName } = getters
      if (currentModuleName) {
        return rootGetters[`${currentModuleName}/canRead`]
      }
      return false
    },
    canCreate: (state, getters, rootState, rootGetters) => {
      const { currentModuleName } = getters
      if (currentModuleName) {
        return rootGetters[`${currentModuleName}/canCreate`]
      }
      return false
    },
    canUpdate: (state, getters, rootState, rootGetters) => {
      const { currentModuleName } = getters
      if (currentModuleName) {
        return rootGetters[`${currentModuleName}/canUpdate`]
      }
      return false
    },
    props: (state, getters, rootState, rootGetters) => {
      const { currentModuleName } = getters
      if (currentModuleName) {
        return rootState[currentModuleName].props
      }
      return false
    },
    fields: (state, getters, rootState, rootGetters) => {
      const { currentModuleName } = getters
      if (currentModuleName) {
        return rootGetters[`${currentModuleName}/fields`]
      }
      return false
    },
    makeRel: (state, getters, rootState, rootGetters) => {
      const { currentDoc, currentModuleName } = getters
      if (currentDoc && currentModuleName) {
        // TODO1 return helpers.makeRel(currentDoc, currentModuleName)
      }
      return false
    },
    unsavedChanges: (state, getters, rootState, rootGetters) => {
      // return _.some(modules, module => rootState[module.moduleName]._sync.patching) // TODO3
      return false
    },
    title: (state, getters, rootState, rootGetters) => {
      const { currentDoc, currentModuleName } = getters
      const customTitle = rootGetters[`${currentModuleName}/title`]
      if(customTitle) {
        return customTitle
      } else if (currentDoc && currentDoc.name) {
        return `${currentDoc.name} (${_.upperFirst(currentModuleName)})`
      }
      return _.upperFirst(currentModuleName)
    }
  }
}
