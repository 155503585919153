module.exports = {
  name: null,
  resolution: null,
  state: 'received',
  solution: null,
  reason: null,
  position: null,
  part: null,
  revision: null,
  number: null,
  number_array: null,
  replacement: null,
  progress: {
    repaired: {
      at: null,
      by: null
    },
    completed: {
      at: null,
      by: null
    }
  }
}