import { mapGetters } from 'vuex'
import _ from 'lodash'

import OptionsMixin from './OptionsMixin'

export default {
  mixins: [OptionsMixin],
  data () {
    return {
      typing: ''
    }
  },
  computed: {
    remainingOptions () {
      return this.optionsArray
    },
    availableOptions () {
      let self = this

      return self.typing ? _.filter(self.remainingOptions, option => {
        return option[this.optionLabel]
          .toString()
          .toLowerCase()
          .indexOf(self.typing.toLowerCase()) >= 0
      }) : self.remainingOptions
    }
  },
  methods: {
    onTyping (input) {
      this.typing = input
      // this.$emit('change', input)
    }
  }
}
