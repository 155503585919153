import _ from 'lodash'
import firebase from 'firebase'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import AsyncComputed from 'vue-async-computed'
// import 'buefy/dist/buefy.css'

import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue'
import { sync } from 'vuex-router-sync'

import { mapState, mapGetters, mapActions } from 'vuex'

import App from './App.vue'
import db from './database'
import router from '@/router'
import { modules } from '@/store'
import { numberFormats } from '../functions/vueConfig'


import auth from '@/auth'

import login from '@/login'

import AValue from '@/components/Form/AValue'
import ATable from '@/components/ATable'
import AInput from '@/components/Form/AInput'
import ASelect from '@/components/Form/ASelect'
import AAutocomplete from '@/components/Form/AAutocomplete'
import ASuggestInput from '@/components/Form/ASuggestInput'
import ATaginput from '@/components/Form/ATaginput'
import ADatepicker from '@/components/Form/ADatepicker'
import AUpload from '@/components/Form/AUpload'
import ACard from '@/components/ACard'
import AddDocButton from '@/components/AddDocButton'
import RoughDocument from '@/components/RoughDocument'

import manualDoc from '@/components/mixins/manualDoc'
import manualModule from '@/components/mixins/manualModule'
import Buefy from 'buefy'
import VueGoogleCharts from 'vue-google-charts'

import '@/assets/scss/app.scss'
import callables from '@/callables'

import defaultSettings from '../functions/defaultSettings'
import { mergeRoutesCustomizer } from '../functions/helpers'

window.defaultSettings = defaultSettings
window.updatedSettings = _.cloneDeep(defaultSettings)
window.mergeSettings = (userSettings) => _.mergeWith(window.updatedSettings, _.cloneDeep(userSettings), mergeRoutesCustomizer)
window.callables = callables
window._ = _
window.firebase = firebase
window.moment = moment
Vue.use(Buefy, {
  defaultFieldLabelPosition: 'on-border',
  defaultFirstDayOfWeek: 1
})
Vue.use(VueGoogleCharts)
Vue.config.productionTip = false

Vue.use(AsyncComputed)
Vue.use(CKEditor)
Vue.use(VueI18n)
sync(store, router)

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    callables.setUserRoutesDefaults()
    await store.dispatch('user/openDBChannel', user.uid)
    Vue.mixin({
      components: {
        AValue,
        ATable,
        AInput,
        ASelect,
        ASuggestInput,
        AAutocomplete,
        ATaginput,
        ADatepicker,
        AUpload,
        ACard,
        AddDocButton,
        RoughDocument
      },
      mixins: [manualDoc, manualModule],
      computed: {
        ...mapState({
          auth: state => state.auth.data,
          app: state => state.user.app,
          route: state => state.route
        }),
        ...mapGetters({
          config: 'user/getActualRouteConfig',
          currentDoc: 'currentDoc/currentDoc',
          currentModuleName: 'currentDoc/currentModuleName',
          canRead: 'currentDoc/canRead',
          user: 'user/data'
        }),
        assetsPath: () => `${process.env.BASE_URL}assets/`
      },
      methods: {
        openDatepicker (props) {
          const { events } = props
          this.$buefy.modal.open({
            parent: this,
            component: ADatepicker,
            width: 400,
            props,
            events
          })
        },
        upperFirst: _.upperFirst
      }
    })

    let locale = store.getters["user/data"].locale
    if(!locale) {
      locale = 'en'
      await store.dispatch('user/update', {
        path: 'locale',
        value: locale
      })
    }
    await store.dispatch('locales/openDBChannel', { where: [['id', '==', locale]] })
    const messages = store.state.locales.data

    moment.locale(locale)

    const i18n = new VueI18n({
      locale,
      messages,
      numberFormats
    })

    window.app = new Vue({
      router,
      store,
      i18n,
      created () {
        this.$store.dispatch('auth/setUser', user)

        /*_.forEach(modules, async module => await this.$store.dispatch(`${module.moduleName}/initReadRules`)) TODO fix helpers.mergeWhereArray (_gte), if external customer access needs to be implemented*/
        if (this.user && !this.user.role) {
          this.$buefy.dialog.alert('You are a new registered user, please wait for administrator to confirm your registration')
        }
      },
      render: h => h(App)
    })
    store.$app = app
    app.$mount('#app')
  } else {
    login()
  }
})

