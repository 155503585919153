const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'parts',
  moduleName: 'parts',
  state: {
    moduleName: 'parts',
    props,
    list: {
      orderBy: 'name,asc',
      orderOptions: {
        'name,asc': 'Part type ascending'
      },
      initialFilters: {
        supplier: null
      }
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader } = getters
      return isAdmin || isReader
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
