const customers = require('./defaultSettings/customers')
const customer = require('./defaultSettings/customer')
const depots = require('./defaultSettings/depots')
const depot = require('./defaultSettings/depot')
const failureNodes = require('./defaultSettings/failureNodes')
const failureNode = require('./defaultSettings/failureNode')
const failureParts = require('./defaultSettings/failureParts')
const failurePart = require('./defaultSettings/failurePart')
const failureSupplies = require('./defaultSettings/failureSupplies')
const failureSupplie = require('./defaultSettings/failureSupplie')
const failures = require('./defaultSettings/failures')
const failure = require('./defaultSettings/failure')
const operators = require('./defaultSettings/operators')
const operator = require('./defaultSettings/operator')
const parts = require('./defaultSettings/parts')
const part = require('./defaultSettings/part')
const ramsNodes = require('./defaultSettings/ramsNodes')
const ramsNode = require('./defaultSettings/ramsNode')
const suppliers = require('./defaultSettings/suppliers')
const supplier = require('./defaultSettings/supplier')
const users = require('./defaultSettings/users')
const user = require('./defaultSettings/user')
const vehicleTypes = require('./defaultSettings/vehicleTypes')
const vehicleType = require('./defaultSettings/vehicleType')
const vehicles = require('./defaultSettings/vehicles')
const vehicle = require('./defaultSettings/vehicle')
const dashboard = require('./defaultSettings/dashboard')

module.exports = {
customers,
customer,
depots,
depot,
failureNodes,
failureNode,
failureParts,
failurePart,
failureSupplies,
failureSupplie,
failures,
failure,
operators,
operator,
parts,
part,
ramsNodes,
ramsNode,
suppliers,
supplier,
users,
user,
vehicleTypes,
vehicleType,
vehicles,
vehicle,
dashboard
}
