<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ computedHeader }}
      </p>
    </header>
    <section class="modal-card-body">
      <b-field>
        <b-datepicker
          :value="value"
          inline
          :locale="user.locale"
          @input="updateAndClose"
        />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-dark"
        type="button"
        @click="$parent.close()"
      >
        {{ $t('common.close') }}
      </button>
      <button
        class="button is-default"
        type="button"
        @click="update(null)"
      >
        {{ $t('common.reset') }}
      </button>
    </footer>
  </div>
</template>

<script>
import Mixin from './Mixin'

export default {
  mixins: [Mixin],
  props: {
    header: {
      type: String,
      required: false
    }
  },
  computed: {
    computedHeader() {
      return this.header// || this.$t('components.form.aDatepicker.header')
    }
  },
  methods: {
    updateAndClose(value) {
      this.update(value)
      this.$parent.events.close()
      this.$parent.close()
    }
  }
  /* methods: {
    change (value) {
      this.$parent.$parent.$emit('change', value ? moment(value).format('YYYY-MM-DD') : null)// HH:mm:ss'))
      this.$parent.close()
    }
  } */
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
