module.exports = {
  firestorePath: '',
  // The path to a collection or doc in firestore. You can use `{userId}` which will be replaced with the user Id.
  firestoreRefType: 'collection',
  // `'collection'` or `'doc'`. Depending on your `firestorePath`.
  moduleName: '',
  // The module name. Can be nested, eg. `'user/items'`
  statePropName: 'data',
  // The name of the property where the docs or doc will be synced to. If left blank it will be synced on the state of the module.
  logging: true,
  // namespaced: true, // automatically added
  // Related to the 2-way sync:

  sync: {
    where: [],
    // orderBy: ['created_at', 'desc'],
    /* defaultValues: {
      'created_by': 1
    }, */
    preventInitialDocInsertion: false,
    debounceTimerMs: 1000
    // HOOKS for local changes:
    // insertHook: function (updateStore, doc, store) { return updateStore(doc) },
    // patchHook: function (updateStore, doc, store) { return updateStore(doc) },
    // deleteHook: function (updateStore, id, store) { return updateStore(id) },
    // // HOOKS for local batch changes:
    // insertBatchHook: function (updateStore, docs, store) { return updateStore(docs) },
    // patchBatchHook: function (updateStore, doc, ids, store) { return updateStore(doc, ids) },
    // deleteBatchHook: function (updateStore, ids, store) { return updateStore(ids) }
  },

  // When items on the server side are changed:

  // When items are fetched through `dispatch('module/fetch', filters)`.
  fetch: {
    // The max amount of documents to be fetched. Defaults to 50.
    docLimit: 5
  }
}
