const { state } = require('./options')

module.exports = [
  { field: 'part', label: 'parts.sg', default: {}, options: 'parts' },
  { field: 'number', label: 'common.number' },
  { field: 'number_array', label: false },
  { field: 'name' },
  { field: 'replacement.number', label: 'common.replacement_number' },
  { field: 'failure.progress.reported.at', timestamp: true },

  { field: 'decomposition', default: {} },
  { field: 'operating_since', timestamp: true },
  { field: 'daysInOperation' }
]
