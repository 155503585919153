const _ = require('lodash')
const moment = require('moment')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')
const { getFailureSupplyNumber } = require('../../callables')
const makeFailureRef = require('../failures/makeRef')
// const router = require('@/router') TODO3

module.exports = {
  firestorePath: 'failureParts',
  moduleName: 'failureParts',
  state: {
    moduleName: 'failureParts',
    props,
    list: {
      initialFilters: {
        failure: {
          operator: null,
          type: null,
          progress: {
            reported: {
              at_gte: null
            }
          }
        },
        part: {
          supplier: null,
          id: null //TODO ve filtrech if id && id not null
        },
        state: [],
        number: null,
        number_array: null
      },
      orderBy: 'failure.progress.reported.at',
      orderOptions: {
        'failure.progress.reported.at': 'Reported at'
      }
    },
    form: {
      hideParts: false
    }
  },
  actions: {
    belongsToCustomer ({ dispatch, rootGetters }) {
      dispatch('mergeWhere', ['failure.customer.id', '==', rootGetters['user/customerId']])
    },
    async makeFailureSupply ({ dispatch, rootGetters, getters, commit }, payload) {
      const { supplierName, note, internalNote } = payload
      const { currentFailurePartsToDispatch } = getters
      const failure = makeFailureRef(rootGetters['currentDoc/currentDoc'])
      const issuer = _.pick(rootGetters['user/data'], ['id', 'name', 'email', 'phone'])
      const failureParts = currentFailurePartsToDispatch[supplierName]
      const supplierId = failureParts[0].part.supplier.id
      await dispatch('suppliers/fetchById', supplierId, {root: true})
      const supplier = _.find(rootGetters['suppliers/items'], { id: supplierId })
      const { number, sequenceNumber } = await getFailureSupplyNumber()
      const id = await dispatch('failureSupplies/set', {
        failure,
        supplier,
        issuer,
        number,
        sequenceNumber,
        note,
        internalNote
      }, { root: true })
      const failureSupply = _.find(rootGetters['failureSupplies/items'], { id })
      _.forEach(failureParts, async (failurePart) => await dispatch('set', { id: failurePart.id, failureSupply, state: 'repairing' }), { root: true })
      return id
      // router.push({ name: 'failureSupplie', params: { id } }) TODO3
    }
  },
  getters: {
    currentFailureItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const currentFailureId = rootGetters['failures/currentFailureId']
      if (items && currentFailureId) {
        return _.filter(items, item => item.failure.id === currentFailureId)
      }
      return null
    },
    currentFailureRootItems: (state, getters, rootState, rootGetters) => {
      const { currentFailureItems } = getters
      return _.filter(currentFailureItems, item => item.parent === null)
    },
    rootItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      return _.filter(items, item => item.parent === null)
    },
    currentSupplyItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const currentSupplyId = rootGetters['failureSupplies/currentSupplyId']
      if (items && currentSupplyId) {
        return _.filter(items, item => item.failureSupply && item.failureSupply.id === currentSupplyId)
      }
      return null
    },
    dispatchedCurrentFailureParts: (state, getters, rootState) => {
      const { currentFailureItems } = getters
      if (currentFailureItems) {
        const dispatched = _.filter(currentFailureItems, item => item.failureSupply)
        return _.groupBy(dispatched, 'failureSupply.id')
      }
      return null
    },
    undispatchedCurrentFailureParts: (state, getters, rootState) => {
      const { currentFailureItems } = getters
      if (currentFailureItems) {
        return _.filter(currentFailureItems, item => !item.failureSupply)
      }
      return null
    },
    currentFailurePartsToDispatch: (state, getters, rootState) => {
      const { undispatchedCurrentFailureParts } = getters
      if (undispatchedCurrentFailureParts) {
        const toDispatch = _.filter(undispatchedCurrentFailureParts, item => item.solution === 'external repair' && item.part.supplier)
        return _.groupBy(toDispatch, 'part.supplier.name')
      }
      return null
    },
    hasUnrepairedCurrentFailureParts: (state, getters, rootState) => {
      const { currentFailureItems } = getters
      if (currentFailureItems) {
        const unrepaired = _.filter(currentFailureItems, item => !_.includes(['repaired', 'completed'], item.state))
        console.warn('unrepaired', unrepaired)
        return unrepaired.length
      }
      return true
    },
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || isCustomer || isReader || isTechnician
    },
    canUpdate: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      const documentState = rootGetters['currentDoc/currentDoc'] ? rootGetters['currentDoc/currentDoc'].failure.state : null
      return isAdmin || (isTechnician && documentState !== 'completed')
    },
    title: (state, getters, rootState, rootGetters) => {
      const doc = rootGetters["currentDoc/currentDoc"]
      if(doc) {
        return (doc.part ? doc.part.name : '')
      }
      return null
    },
    rowClass: (state) => (row) => {
      switch (row.state) {
        case 'draft':
          return 'has-text-grey'
        case 'received':
          return 'has-background-warning'
        case 'repairing':
          return 'has-background-info'
        case 'repaired':
          return 'has-background-success'
        case 'completed':
          return 'has-background-grey-lighter has-text-grey'
        default:
          return ''
      }
    }
  },
  serverChange: {
    convertTimestamps
    /*addedHook: function (updateStore, doc, id, store) {
      console.log({ doc, id, store })
      doc.hello = function() {
        return this.id + this.name
      }
      return updateStore(doc)
    }*/
  },
  sync: {
    defaultValues,
    patchHook: function (updateStore, change, store) {
      if(change.state) {
        change.progress = {
          [change.state]: {
            at: moment().toDate(),
            by: store.getters["user/user"]
          }
        }

        if(_.includes(['repaired'], change.state)) {
          const doc = store.state.failureParts.data[change.id]
          if(doc.parent === null && (!doc.name || !doc.resolution)) {
            store.dispatch("failureParts/openRequiredForm", { change, updateStore })
          } else {
            return updateStore(change)
          }
        } else {
          return updateStore(change)
        }
      }
      return updateStore(change)
    }
  }
}
