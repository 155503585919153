module.exports = {
  failure: {
    progress: {
      reported: {
        at: '%convertTimestamp%'
      }
    }
  },
  operating_since: '%convertTimestamp%',
  created_at: '%convertTimestamp%',
  updated_at: '%convertTimestamp%'
}