<template>
  <div
    v-on-clickaway="doneEditing"
    class="card"
  >
    <header
      v-if="header || editable || $slots['header-buttons']"
      class="card-header"
    >
      <p class="card-header-title">
        <span class="is-pulled-left">
          {{ header }}
        </span>
        <slot
          name="header-title"
        />
      </p>
      <a
        class="card-header-icon"
        aria-label="edit"
      >
        <slot
          name="header-buttons"
        />
        <b-icon
          v-if="editable && canUpdate"
          :icon="isEditing ? 'checkbox-marked-circle-outline': 'pencil'"
          @click.native="toggleEditing"
        />
        <button
          v-if="closable"
          type="button"
          aria-label="Close message"
          class="delete"
          @click="close"
        />
      </a>
    </header>
    <div class="card-content">
      <div class="content">
        <slot
          v-if="isEditing"
          name="form"
        />
        <slot
          v-if="!isEditing"
          name="default"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [ clickaway ],
  props: {
    header: {
      type: String | Number,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isEditing: false
    }
  },
  computed: {
    canUpdate () {
      return this.$store.getters[`${this.module}/canUpdate`]
    }
  },
  methods: {
    toggleEditing () {
      this.isEditing = !this.isEditing
      if (this.isEditing) {
        this.$emit('startedEditing')
      }
    },
    doneEditing () {
      this.isEditing = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
