const role = [
  'reader',
  'customer',
  'technician',
  'admin'
]

module.exports = {
  role
}
