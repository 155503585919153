module.exports = {
  moduleName: 'failureSupplies',
  columns: [
    {
      field: 'number',
      label: 'common.number',
      visible: true
    },
    {
      field: 'supplier.name',
      label: 'suppliers.ref.name',
      visible: true
    },
    {
      field: 'issuer.name',
      label: 'failureSupplies.columns.issuer.name',
      visible: true
    },
    {
      field: 'failure.customer.name',
      label: 'customers.ref.name',
      visible: true
    },
    {
      field: 'failure.operator.name',
      label: 'operators.ref.name',
      visible: true
    },
    {
      field: 'failure.vehicle.number',
      label: 'vehicles.ref.number',
      visible: false
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {},
  orderBy: 'created_at,desc'
}