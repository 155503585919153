module.exports = {
  part: {},
  state: 'received',
  number: null,
  number_array: null,
  name: null,
  resolution: null,
  replacement: {
    number: null
  },
  progress: {
    repaired: {
      at: null,
      by: null
    },
    completed: {
      at: null,
      by: null
    }
  }
}