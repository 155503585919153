const { reason, solution, state } = require('./options')

module.exports = [
  { field: 'name', label: 'common.description' },
  { field: 'resolution', label: 'common.resolution' },
  { field: 'state', label: 'common.state', options: state, default: 'received' },
  { field: 'solution', options: solution },
  { field: 'reason', options: reason },
  { field: 'position' },

  { field: 'part', label: 'parts.sg' },
  { field: 'revision' },
  { field: 'number', label: 'common.number' },
  { field: 'number_array', label: false },
  { field: 'replacement', label: 'common.replacement' },

  { field: 'progress.repaired.at', timestamp: true },
  { field: 'progress.repaired.by' },
  { field: 'progress.completed.at', timestamp: true },
  { field: 'progress.completed.by' }
]
