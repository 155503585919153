const reason = [
  'mechanical damage',
  'electrical damage',
  'preventive replacement',
  'other requirements'
]

const solution = [
  'internal repair',
  'external repair',
  'liquidation'
]

const state = [
  'received',
  'repairing',
  'repaired',
  'completed'
]

module.exports = {
  reason,
  state,
  solution
}
