const _ = require('lodash')
const Vue = require('vue')
const helpers = require('../helpers')
const firebase = require('firebase')

module.exports = {
  firestorePath: 'users/{userId}',
  firestoreRefType: 'doc',
  moduleName: 'user',
  statePropName: 'data',
  logging: true,
  sync: {
    where: [],
    preventInitialDocInsertion: false,
    debounceTimerMs: 1000
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%',
      updated_at: '%convertTimestamp%'
    }
  },
  fetch: {
    docLimit: 5
  },
  state: {
    app: {
      editMode: false,
      sidebar: true
    },
    recentFailures: [],
    locale: 'en'
  },
  actions: {
    setCurrentUser ({ commit, dispatch }, currentUser) {
      commit('SET_CURRENT_USER', currentUser)
    },
    update ({ state, dispatch }, payload) {
      const newObj = _.set({}, payload.path, payload.value)
      dispatch('set', newObj)
    },
    init ({ state, dispatch }, payload) {
      if (!_.has(state.data, payload.path)) {
        dispatch('update', payload)
      }
    },
    setAppMode ({ commit }, mode) {
      commit('SET_APP_MODE', mode)
    },
    openSidebar ({ commit }, mode) {
      commit('SET_SIDEBAR', true)
    },
    closeSidebar ({ commit }, mode) {
      commit('SET_SIDEBAR', false)
    },
    switchAppMode ({ commit, state }) {
      commit('SET_APP_MODE', !state.app.editMode)
    },
    updateRecentFailures ({ dispatch, getters }, newFailure) {
      const failure = _.pick(newFailure, ['id', 'name', 'operator.name'])
      if(!getters.recentFailures[0] || (getters.recentFailures[0].id !== failure.id)) {
        let newFailures = _.cloneDeep(getters.recentFailures)
        newFailures.unshift(failure)
        const value =_.slice(_.uniqBy(newFailures, 'id'), 0, 10)
        console.log('newRecentFAilures ', value)
        dispatch('update', {
          path: `recentFailures`,
          value
        })
      }
    },
    logout ({ commit, state }) {
      console.log('logging out')
      commit('LOGOUT')
    },
    mergeWithDefaults ({ dispatch, state }, payload) {
      function customizer (objValue, srcValue, key, object, source, stack) {
        // DEV
        if (key === 'orderBy' && _.isArray(source.orderBy) && source.orderBy.length === 0) {
          return object.orderBy
        }

        if (key === 'tooltips') {
          return object.tooltips
        }

        if (_.isArray(objValue) && !srcValue) {
          console.warn('returning template value', objValue)
          return objValue
        }

        /*if (_.isArray(objValue) && (!srcValue.length || _.isString(srcValue[0]))) {
          console.warn('key', key)
          debugger
        }*/

        if (_.isArray(objValue)) {
          if((objValue[0] && _.isString(objValue[0])) || (srcValue[0] && _.isString(srcValue[0]))) {
            return srcValue
          } else {
            let unionByKey = null
            const comparableKeys = ['id', 'value', 'field']
            comparableKeys.some(function (key) {
              if (_.has(objValue[0], key)) {
                unionByKey = key
                return true
              }
            })
            // console.log(srcValue, objValue)
            return _.filter(_.unionBy(srcValue, objValue, unionByKey), mixedItem => {
              // console.log(unionByKey, mixedItem[unionByKey])
              return _.find(objValue, actualItem => actualItem[unionByKey] === mixedItem[unionByKey])
            })
          }
        }
      }
      const { pathInModule, defaultState } = payload
      dispatch('patch', _.mergeWith(_.cloneDeep(defaultState), _.get(state, pathInModule), customizer))
    }
  },
  mutations: {
    SET_CURRENT_USER (state, currentUser) {
      state.currentUser = currentUser
      state.uid = currentUser.uid
    },
    SET_APP_MODE (state, mode) {
      state.app.editMode = mode
    },
    SET_SIDEBAR (state, opened) {
      state.app.sidebar = opened
    },
    async LOGOUT (state, mode) {
      await firebase.auth().signOut()
      location.reload()
    }
  },
  getters: {
    getActualRouteConfig: (state, getters, rootState) => {
      const { route } = rootState
      return state.data.routes[route.name]
    },
    data: (state) => state.data || null,
    user: (state) => state.data ? _.pick(state.data, ['id', 'name']) : null,
    sidebar: (state) => state.app.sidebar,
    recentFailures: (state) => state.data.recentFailures,
    role: (state) => state.data && state.data.role ? state.data.role : null,
    locale: (state) => state.data.locale,
    isSuperAdmin: (state, getters, rootState, rootGetters) => state.data.id === 'wVXjGxOeDbPGzm6KxtcXh6eP1Xl1',
    isAdmin: (state, getters, rootState, rootGetters) => state.data.role === 'admin',
    isTechnician: (state, getters, rootState, rootGetters) => state.data.role === 'technician',
    isReader: (state, getters, rootState, rootGetters) => state.data.role === 'reader',
    isCustomer: (state, getters, rootState, rootGetters) => state.data.role === 'customer',
    routes: (state) => state.data.routes || null,
    customerId: (state) => state.data.customer && state.data.customer.id ? state.data.customer.id : null
  }
}
