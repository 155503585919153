<template>
  <b-table :data="toPairs(doc)">
    <template #default="props">
      <b-table-column
        label="prop"
      >
        {{ props.row[0] }}
      </b-table-column>

      <b-table-column
        label="value"
      >
        {{ props.row[1] }}
      </b-table-column>
    </template>
  </b-table>
</template>

<script>
import { toPairs } from 'lodash'

export default {
  methods: {
    toPairs
  }
}
</script>
