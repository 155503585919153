module.exports = {
  moduleName: 'depots',
  columns: [
    {
      field: 'name',
      label: 'common.name',
      visible: true
    },
    {
      field: 'officialName',
      label: 'common.official_name',
      visible: true
    },
    {
      field: 'address',
      label: 'common.address',
      visible: true
    },
    {
      field: 'city',
      label: 'common.city',
      visible: true
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {},
  orderBy: 'name,asc',
  orderOptions: {
    'name,asc': 'Depot name ascending'
  }
}