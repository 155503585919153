<template>
  <div>
    <h2>{{ $t('layout.settings.header') }}</h2>
    <b-button
      @click="resetColumns"
    >
      {{ $t('layout.settings.reset') }}
    </b-button>
    <columns-sorter
      :user-settings-key="`${userRoutePath}.columns`"
    />
  </div>
</template>

<script>

import ColumnsSorter from '@/components/ColumnsSorter'
import { setDefaultColumns } from '@/callables'

export default {
  components: {
    ColumnsSorter
  },
  props: {
    moduleName: {
      type: String,
      required: false
    }
  },
  computed: {
    userRoutePath () {
      return this.moduleName || this.route.name
    }
  },
  methods: {
    resetColumns() {
      setDefaultColumns(this.userRoutePath)
    }
  }
}
</script>
