<template>
  <a-field
    :type="fieldType"
    :field="field"
    :label="Boolean($slots.label) ? '' : label"
  >
    <slot
      v-if="$slots.label"
      name="label"
    />

    <b-input
      ref="input"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      :type="type"
      :icon="updateOnClick ? 'magnify' : ''"
      icon-clickable
      @input="onInput"
      @icon-click="onClick"
      @keyup.native.enter="onEnterPress"
      @blur="onBlur"
    />
  </a-field>
</template>

<script>

import Mixin from './Mixin'
import AField from './AField'

export default {
  components: { AField },
  mixins: [Mixin],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    updateOnClick: {
      type: Boolean,
      default: false
    },
    updateOnBlur: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput () {
      const value = this.$refs.input.newValue
      if (!this.updateOnClick && !this.updateOnBlur) {
        this.update(value)
      } else {
        if(!value) {
          this.update(value)
        }
      }
    },
    onClick () {
      const value = this.$refs.input.newValue
      this.update(value)
    },
    onEnterPress(ev) {
      if (this.updateOnClick) {
        this.onClick()
      }
    },
    onBlur(ev) {
      if (this.updateOnBlur) {
        const value = this.$refs.input.newValue
        this.update(value)
      }
    }
  }
}
</script>
