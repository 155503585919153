<style scoped>
  /deep/ .loading-overlay .loading-icon:after {
    border: 2px solid red;
    border-top-color: transparent;
    width: 1em;
    height: 1em;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
  }
</style>

<template>
  <div>
    <b-navbar
      close-on-click
    >
      <template #brand>
        <b-navbar-item href="/">
          <router-link :to="{ name: 'failures' }">
            <img src="@/assets/logo.png">
          </router-link>
        </b-navbar-item>

        <div class="navbar-item">
          <b-loading
            v-if="unsavedChanges"
            active
            :is-full-page="false"
            :can-cancel="false"
          />
          <b-icon
            v-else
            icon="check"
            type="is-success"
          />
        </div>
      </template>

      <template #start>
        <b-navbar-item
          v-for="name in modules"
          :key="name"
          :to="{ name }"
          :active="route.name === name"
          tag="router-link"
        >
          {{ $t(`${name}.pl`) }}
          </a>
        </b-navbar-item>

        <div
          v-if="adminModules.length && isAdmin"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-link">
            {{ $t('common.more') }}
          </a>

          <div class="navbar-dropdown">
            <b-navbar-item
              v-for="name in adminModules"
              :key="name"
              :to="{ name }"
              tag="router-link"
            >
              {{ $t(`${name}.pl`) }}
              </a>
            </b-navbar-item>
          </div>
        </div>
      </template>

      <template #end>
        <b-navbar-item
          v-if="isAdmin || isReader"
          to="/dashboard"
          tag="router-link"
        >
          {{ $t('dashboard.sg') }}
        </b-navbar-item>

        <template
          v-if="auth.uid"
        >
          <div class="navbar-item">
            <a
              class="button"
              @click="switchAppMode"
            >
              <b-icon
                icon="cog"
              />
            </a>
          </div>

          <b-navbar-item
            :to="{ name: 'user', params: { id: user.id } }"
            tag="router-link"
          >
            {{ auth.email }}<br>
            {{ user.role }} {{ user.customer ? `@ ${user.customer.name} (${user.customer.id})` : '' }}
          </b-navbar-item>
          <div class="navbar-item">
            <a
              class="button"
              @click="logout"
            >
              <b-icon
                icon="logout"
              />
            </a>
          </div>
          <div class="navbar-item">
            <locale-switch />
          </div>
        </template>

        <div
          v-else
          class="buttons navbar-item"
        >
          <a class="button is-primary">
            <strong>{{ $t('header.signup') }}</strong>
          </a>
          <a class="button is-light">
            {{ $t('header.login') }}
          </a>
        </div>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { upperFirst } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import LocaleSwitch from './LocaleSwitch'

export default {
  components: {
    LocaleSwitch
  },
  computed: {
    ...mapGetters({
      unsavedChanges: 'currentDoc/unsavedChanges',
      isAdmin: 'user/isAdmin',
      isTechnician: 'user/isTechnician',
      isReader: 'user/isReader'
    }),
    modules () {
      return ['failures', 'failureParts', 'failureNodes', 'failureSupplies']
    },
    adminModules () {
      let modules = ['customers', 'operators', 'users', 'vehicleTypes', 'vehicles', 'parts', 'suppliers']
      return _.filter(modules, module => this.$store.getters[`${module}/canRead`])
    }
  },
  methods: {
    ...mapActions({
      switchAppMode: 'user/switchAppMode',
      logout: 'user/logout'
    }),
    upperFirst
  }
}
</script>
