<template>
  <div v-if="!progress">
    <!-- <b-field
      :label="$t('components.form.aUpload.type')"
    >
      <b-input
        v-model="type"
        :placeholder="$t('components.form.aUpload.select_type')"
      />
    </b-field> -->

    <b-field
      class="file"
    >
      <b-upload
        v-model="files"
        multiple
        drag-drop
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                icon="upload"
                size="is-large"
              />
            </p>
            <p>{{ $t('components.form.aUpload.drop_files_here') }}</p>
          </div>
        </section>
      </b-upload>
    </b-field>

    <div class="tags">
      <span
        v-for="(file, index) in files"
        :key="index"
        class="tag is-primary"
      >
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteFile(index)"
        />
      </span>
    </div>
    <b-button
      v-if="files.length"
      type="is-primary"
      @click="uploadFiles"
    >
      Upload files
    </b-button>
  </div>
  <b-progress
    v-else
    :value="progress"
    :type="progress === 100 ? 'is-success' : 'is-default'"
    show-value
    format="percent"
  >
    {{ progress === 100 ? $t('components.form.aUpload.completed') : $t('common.progress', progress) }}
  </b-progress>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    existingFiles: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      type: null,
      files: [],
      progress: 0
    }
  },
  computed: {
    ...mapGetters({
      filesPath: 'currentDoc/filesPath'
    })
    /* filesPath () {
      return `${this.module}/${this.doc.id}`
    } */
  },
  methods: {
    filePath(file) {
      const filePath = `${this.filesPath}/${file.name}`
      return filePath
    },
    // returns true if file does not exist OR if user confirmed to overwrite existing file
    async checkExistingFile(file, index) {
      const existingFiles = _.map(this.existingFiles, 'location.path')
      const filePath = this.filePath(file)

      console.log({ existingFiles, filePath })
      if(_.includes(existingFiles, filePath)) {
        await this.$buefy.dialog.confirm({
          message: `Soubor ${file.name} existuje. prepsat?`,
          // message: this.$t('components.node.delete.confirm'),
          type: 'is-warning',
          confirmText: 'Prepsat',
          onConfirm: async () => {
            this.uploadFile(file)
          },
          onCancel: async () => {
            this.deleteFile(index)
          }
        })
      } else {
        this.uploadFile(file)
      }
    },
    uploadFiles() {
      _.forEach(this.files, this.checkExistingFile)
    },
    uploadFile (file) {

      const self = this

      const filePath = this.filePath(file)

      let metadata = {
        customMetadata: {
          type: this.type
        }
      }

      console.log('metadata', metadata)

      let storageRef = firebase.storage().ref()

      let uploadTask = storageRef.child(filePath).put(file, metadata)

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    function (snapshot) {
                      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                      self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                      switch (snapshot.state) {
                      case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused')
                        break
                      case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running')
                        break
                      }
                    }, function (error) {
                      // A full list of error codes is available at
                      // https://firebase.google.com/docs/storage/web/handle-errors
                      switch (error.code) {
                      case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break

                      case 'storage/canceled':
                        // User canceled the upload
                        break

                      case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break
                      }
                    }, function () {
                      self.$emit('completed')
                      // Upload completed successfully, now we can get the download URL
                      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        console.log('File available at', downloadURL)
                      })
                    })
    },
    deleteFile(index) {
      this.files.splice(index, 1)
    }
  }
}
</script>
