const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'suppliers',
  moduleName: 'suppliers',
  state: {
    moduleName: 'suppliers',
    props,
    list: {
      orderBy: 'name,asc'
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader } = getters
      return isAdmin || isReader
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
