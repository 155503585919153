const _ = require('lodash')
const Vue = require('vue')
const helpers = require('../helpers')

module.exports = {
  async fetchDocs ({ dispatch, state, getters }, payload = {}) {
    const { routeSettings } = getters

    // let where = _.clone(state.sync.where)
    let where = helpers.filtersToWhereConditions(routeSettings.where)
    /*_.forEach(tmp, (condition) => {
      helpers.mergeWhereArray(where, condition)
    })*/
    let orderBy = _.clone(routeSettings.orderBy)


    // let orderBy = ['created_at', 'desc']
    const identifier = helpers.createFetchIdentifier(_.assign({
      where,
      orderBy
    }, payload))
    try {
      const result = await dispatch('fetchAndAdd', _.assign({ where, orderBy: orderBy.split(',') }, payload))

      let done = false
      if(result.done) {
        done = true
      } else {
        if(result.query._query.limit === null || result.size < result.query._query.limit) {
          done = true
        } else if(result.done) {
          done = true
        }
      }

      // console.warn(`Fetched ${result.size} of ${result.query._query.limit}`, `Done: ${result.done} ? ${done}`)

      await dispatch('updateFetchIdentifier', identifier)
      dispatch('fetchingDone', { done , identifier })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  async resetFilters ({ dispatch, state }, payload) {
    // console.warn('initialFilters', state.list.initialFilters)
    await dispatch('user/update', {
      path: `routes.${state.moduleName}.where`,
      value: null
    }, { root: true })
    await dispatch('user/update', {
      path: `routes.${state.moduleName}.where`,
      value: state.list.initialFilters
    }, { root: true })
    await dispatch('user/update', {
      path: `routes.${state.moduleName}.orderBy`,
      value: state.list.orderBy
    }, { root: true })
    await dispatch(`${state.moduleName}/fetchDocs`, {}, { root: true })
  },
  mergeWhere ({ commit, state }, payload) {
    commit('MERGE_WHERE', payload)
  },
  updateFetchIdentifier ({ commit, state }, payload) {
    commit('UPDATE_FETCH_IDENTIFIER', payload)
  },
  fetchingDone ({ commit, state }, payload) {
    // console.warn('commiting', payload)
    commit('FETCHING_DONE', payload)
  },
  initReadRules ({ state, getters, dispatch, commit }) {
    const { rules } = state
    if (rules && rules.read) {
      _.forEach(rules.read, (rule) => {
        if (getters[rule]) {
          dispatch(rule)
          return false
        }
      })
    }
  }
}
