const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'ramsNodes',
  moduleName: 'ramsNodes',
  state: {
    moduleName: 'ramsNodes',
    props,
    list: {
      orderByOptions: {
        'created_at,desc': 'Last created'
      },
      initialFilters: {
        part: null,
        state: [],
        number: null,
        number_array: null
      }
    },
    form: {
      hideParts: false
    }
  },
  actions: {
    belongsToCustomer ({ dispatch, rootGetters }) {
      dispatch('mergeWhere', ['failure.customer.id', '==', rootGetters['user/customerId']])
    }
  },
  getters: {
    itemsToExport: (state, getters, rootState, rootGetters ) => {
      const { displayedItems, routeSettings } = getters
      const { columns } = routeSettings
      const columnsToPick = _.filter(columns, column => column.visible)
      const data = _.map(displayedItems, item => {
        let newItem = {}
        _.forEach(columnsToPick, column => {
          let rawValue = _.get(item, column.field) || ''
          newItem[column.field] = column.timestamp && rawValue ? moment(rawValue).format('YYYY-MM-DD') : rawValue
        })
        return newItem
      })
      // const data = _.map(displayedItems, item => flatten(_.pick(item, _.map(_.filter(columns, column => column.visible), 'field'))))
      return data
    },
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isReader } = rootGetters
      return isAdmin || isReader
    },
    canUpdateDocument: (state, getters, rootState, rootGetters) => (document) => {
      const { isAdmin, isCustomer, isReader, isTechnician } = getters
      return isAdmin || (isTechnician && document.state !== 'completed')
    },
    currentFailureItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      const currentFailureId = rootGetters['failures/currentFailureId']
      if (items && currentFailureId) {
        return _.filter(items, item => item.failure.id === currentFailureId)
      }
      return null
    },
    currentFailureRootItems: (state, getters, rootState, rootGetters) => {
      const { currentFailureItems } = getters
      return _.filter(currentFailureItems, item => item.parent === null)
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
