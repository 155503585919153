<template>
  <div
    id="app"
  >
    <div class="sidebar-page">
      <vue-headful
        :title="title"
      />
      <header-navbar class="has-background-white-ter" />
      <section class="section has-background-white-ter sidebar-layout">
        <div class="container is-fluid">
          <!-- <sidebar class="is-hidden-touch" /> -->
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css
"
          >
          <!-- <router-view name="tabs"></router-view> -->
          <div class="columns">
            <div
              v-if="app.editMode"
              class="column is-one-fifth"
            >
              <router-view name="settings" />
            </div>
            <div class="column">
              <router-view
                :key="$route.fullPath"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueHeadful from 'vue-headful'

import HeaderNavbar from '@/components/Layout/HeaderNavbar'
import Sidebar from '@/components/Layout/Sidebar'

export default {
  components: {
    HeaderNavbar,
    VueHeadful,
    Sidebar
  },
  computed: {
    ...mapGetters({
      title: 'currentDoc/title'
    })
  },
  created () {
    if (this.user.role === 'customer') {
      this.$store.dispatch('failures/openDBChannel', { where: [['customer.id', '==', this.$store.getters['user/customerId']]] })
    } else {
      this.$store.dispatch('failures/openDBChannel', { where: [['watches', 'array-contains', '{userId}']] })
      // this.$store.dispatch('failures/fetchAndAdd', { orderBy: ['created_at', 'desc'], limit: 10 })
    }
  }
}
</script>

<style lang="scss">
  .sidebar-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
  }

  .sidebar-page .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
  }
</style>
