module.exports = {
  moduleName: 'failureNodes',
  columns: [
    {
      field: 'part.name',
      label: 'parts.ref.name',
      visible: true
    },
    {
      field: 'number',
      label: 'common.number',
      visible: true
    },
    {
      field: 'state',
      label: 'common.state',
      visible: true
    },
    {
      field: 'name',
      label: 'common.name',
      visible: true
    },
    {
      field: 'failure.customer.name',
      label: 'customers.ref.name',
      visible: true
    },
    {
      field: 'failure.operator.name',
      label: 'operators.ref.name',
      visible: true
    },
    {
      field: 'position',
      label: 'failureNodes.columns.position',
      visible: true
    },
    {
      field: 'failure.type',
      label: 'failures.ref.type',
      visible: false
    },
    {
      field: 'failure.recognized',
      label: 'failures.props.recognized',
      visible: false
    },
    {
      field: 'failure.location',
      label: 'failures.props.location',
      visible: false
    },
    {
      field: 'replacement.number',
      label: 'failureNodes.props.replacement.number',
      visible: false
    },
    {
      field: 'part.supplier.name',
      label: 'suppliers.ref.name',
      visible: false
    },
    {
      field: 'failure.number',
      label: 'failures.ref.number',
      visible: false
    },
    {
      field: 'failure.name',
      label: 'failures.ref.name',
      visible: false
    },
    {
      field: 'parent.part.name',
      label: 'failureNodes.columns.parent.part.name',
      visible: false
    },
    {
      field: 'parent.number',
      label: 'failureNodes.columns.parent.number',
      visible: false
    },
    {
      field: 'service.inspection',
      label: 'failureNodes.ref.service.inspection',
      visible: false
    },
    {
      field: 'service.repair',
      label: 'failureNodes.ref.service.repair',
      visible: false
    },
    {
      field: 'service.testing',
      label: 'failureNodes.ref.service.testing',
      visible: false
    },
    {
      field: 'failure.service.inspection',
      label: 'failures.ref.service.inspection',
      visible: false
    },
    {
      field: 'failure.service.repair',
      label: 'failures.ref.service.repair',
      visible: false
    },
    {
      field: 'failure.service.testing',
      label: 'failures.ref.service.testing',
      visible: false
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {
    part: null,
    state: [],
    number: null,
    number_array: null
  },
  orderBy: 'created_at,desc'
}
