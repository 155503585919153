const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'users',
  moduleName: 'users',
  state: {
    moduleName: 'users',
    props,
    rules: {
      read: ['belongsToCustomer']
    },
    list: {
      orderBy: 'name,asc'
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => {
      const { isAdmin, isCustomer, isReader } = getters
      return isAdmin || isCustomer || isReader
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
