export default {
  props: {
    manualDoc: {
      type: Object,
      required: false
    },
    objectDoc: {
      type: Object,
      required: false
    },
    objectModule: {
      type: String,
      required: false
    }
  },
  computed: {
    doc () {
      if (this.objectDoc) {
        return this.objectDoc
      } else if (this.manualDoc) {
        return this.manualDoc.id
          ? this.$store.state[this.manualDoc.module].data[this.manualDoc.id]
          : this.$store.state[this.manualDoc.module].data
      } else {
        return this.currentDoc
      }
    },
    docPath() {
      if (this.manualDoc) {
        return this.manualDoc
      } else {
        const { name, params } = store.state.route
        if(params.id) {
          return {
            module: name.slice(0, -1),
            id: params.id
          }
        }
        return null
      }
    }
  }
}
