const _ = require('lodash')
const readRules = require('./readRules')
const filters = require('./filters')

module.exports = {
  ...readRules,
  ...filters,
  exportToCsv ({ dispatch, rootGetters, getters, $app }, {
    filename = 'export'
  } = {}) {
    const { itemsToExport, routeSettings } = getters
    const { columns } = routeSettings
    const headers = _.map(_.filter(columns, column => column.visible), item => app.$t(item.label))

    console.log('headers', headers)
    const options = {
      filename,
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      headers
    }
    const { ExportToCsv } = require('export-to-csv')
    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(itemsToExport)
  },
  setWatched ({ dispatch, rootState, commit }, item) {
    const { uid } = rootState.auth.data
    if (item.watches && item.watches.includes(uid)) {
      const newValues = _.without(item.watches, uid)
      dispatch('patch', {
        id: item.id,
        watches: newValues
      })
    } else {
      let newValues = _.clone(item.watches)
      newValues.push(uid)
      dispatch('patch', {
        id: item.id,
        watches: newValues
      })
    }
  },
  openRequiredForm({ state }, payload) {
    const { updateStore, change } = payload
    const documentName = _.upperFirst(state.moduleName.slice(0, -1))
    this.$app.$buefy.modal.open({
      parent: this.$app,
      component: () => import(`@/views/${documentName}/RequiredForm`),
      canCancel: [],
      hasModalCard: true,
      width: 400,
      height: 400,
      props: {
        manualDoc: {
          module: state.moduleName,
          id: change.id
        }
      },
      events: {
        close() {
          return updateStore(change)
        }
      }
    })
  },
  async fetchRest ({ dispatch, state }, payload = {}) {
    await dispatch(`fetchDocs`, { limit: 0 })
  },
  async fetchAll ({ dispatch, state }, payload = {}) {
    await dispatch(`fetchAndAdd`, { limit: 0 })
  },
  clearModule ({ state, getters, dispatch, commit }) {
    dispatch('closeDBChannel', { clearModule: true })
  }
}
