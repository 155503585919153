<template>
  <a-field
    :field="field"
    :label="label"
  >
    <b-autocomplete
      :data="remainingOptions"
      :value="value"
      open-on-focus
      autocomplete="off"
      @typing="onTyping"
      @select="update"
      @blur="updateValueAndSuggestions"
    >
      <template #default="props">
        {{ props.option }}
      </template>
    </b-autocomplete>
  </a-field>
</template>

<script>

import _ from 'lodash'
import { arrayUnion } from 'vuex-easy-firestore'
import Mixin from './Mixin'
import AField from './AField'

export default {
  components: {
    AField
  },
  mixins: [Mixin],
  props: {
    suggestionId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      typing: ''
    }
  },
  computed: {
    remainingOptions() {
      let self = this

      return self.typing ? _.filter(self.options, option => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(self.typing.toLowerCase()) >= 0
      }) : self.options
    },
    options() {
      const doc = this.$store.getters['suggestions/data'][this.suggestionId]
      return doc ? doc.value : []
    }
  },
  created() {
    this.$store.dispatch('suggestions/fetchById', this.suggestionId)
  },
  methods: {
    onTyping (input) {
      this.typing = input
    },
    updateValueAndSuggestions(event) {
      const { value } = event.target
      this.$store.dispatch('suggestions/patch', { id: this.suggestionId, value: arrayUnion(value) })
      this.update(value)
    }
  }
}
</script>
