<style scoped>
  .menu-list a {
    display: inline;
  }
</style>

<template>
  <div>
    <b-table
      v-bind="$attrs"
      :data="computedData"
      :row-class="rowClass"
      v-on="$listeners"
    >
      <b-table-column
        v-if="!hideActions"
        v-slot="props"
        key="id"
        :label="$t('components.aTable.actions')"
      >
        <div class="is-flex">
          <router-link
            :to="routerLinkFn ? routerLinkFn(props) : { name: trimEnd(module, 's'), params: { id: props.row.id }}"
          >
            <b-icon
              v-if="computedCanUpdate"
              icon="pencil"
            />
            <b-icon
              v-else
              icon="eye"
            />
          </router-link>

          <slot
            name="actions"
            :props="props"
          />
        </div>
      </b-table-column>

      <b-table-column
        v-for="column in columns"
        v-slot="props"
        :key="column.field"
        :field="column.field"
        :visible="column.visible"
        :label="true ? $t(column.label) : `${column.field} - ${column.label} = ${$t(column.label)}`"
        :numeric="column.numeric || false"
      >
        <template
          v-if="customComponent(column)"
        >
          <component
            :is="customComponent(column)"
            :row="props.row"
          />
        </template>
        <template v-else>
          {{ value(props.row, column) }}
        </template>
      </b-table-column>


      <empty-table slot="empty" />
    </b-table>
    <!-- <more-docs-button
        :module="moduleName"
      /> -->
  </div>
</template>

<script>

import { trimEnd, get } from 'lodash'
import moment from 'moment'
import Vue from 'vue'

import Tooltip from '@/components/Tooltip'
import EmptyTable from '@/components/EmptyTable'
import MoreDocsButton from '@/components/MoreDocsButton'
import manualModule from '@/components/mixins/manualModule'


export default {
  components: {
    Tooltip,
    EmptyTable,
    MoreDocsButton
  },
  mixins: [manualModule],
  props: {
    data: {
      type: Array,
      required: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    canUpdate: {
      type: Boolean,
      default: undefined
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    routerLinkFn: {
      type: Function,
      required: false
    }
  },
  computed: {
    computedData () {
      return this.data || this.computeDataFn()
    },
    computedCanUpdate () {
      return !_.isUndefined(this.canUpdate) ? this.canUpdate : this.$store.getters[`${this.module}/canUpdate`]
    },
    rowClass (row) {
      return this.$store.getters[`${this.module}/rowClass`]
    },
    defaultColumns() {
      const settings = this.module ? this.$store.getters[`${this.module}/routeSettings`] : null
      return settings ? settings.columns : []
    }

  },
  created() {
    if(this.defaultColumns) {
      _.forEach(this.defaultColumns, column => {
        if(column.customComponent) {
          const fieldComponent = `${_.upperFirst(column.field)}Column`
          Vue.component(
            fieldComponent,
            () => import(`@/views/${_.upperFirst(this.module)}/Columns/${fieldComponent}.vue`)
          )
        }
      })
    }
  },
  methods: {
    trimEnd,
    options(field) {
      const props = this.$store.getters[`${this.module}/props`]
      const prop = _.find(props, { field })
      return prop && prop.options ? prop.options : null
    },
    value (row, column) {
      const value = _.get(row, column.field)
      if (column.timestamp) {
        let dateObject = _.isFunction(value.toDate) ? value.toDate() : value
        return moment(dateObject).format(_.isString(column.timestamp) ? column.timestamp : 'LL')
      } else if (_.isPlainObject(value)) {
        return value[column.displayField || 'name']
      } else if (_.isArray(value)) {
        return value.map(item => item[column.displayField || 'name']).join(',')
      } else if(this.options(column.field)) {
        return value ? this.$t(`${this.module}.options.${column.field}.${_.snakeCase(value)}`) : value
      } else {
        return value
      }
    },
    customComponent(column) {
      const defColumn = this.defaultColumns.find(i => i.field === column.field)
      return defColumn.customComponent ? _.upperFirst(`${column.field}Column`) : false
    }
  }
}
</script>
