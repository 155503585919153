module.exports = {
  name: null,
  description: null,
  resolution: null,
  odometer: null,
  type: 'guarantee',
  recognized: null,
  state: 'draft',
  location: null,
  number: null,
  number_array: [],
  contract_number: null,
  notified: null,
  order_number: null,
  order_number_array: [],
  notes: {},
  contacts: {},
  vehicle: null,
  operator: null,
  customer: null,
  depot: null,
  progress: {
    reported: {
      at: null,
      by: null
    },
    serviced: {
      at: null,
      by: null
    },
    completed: {
      at: null,
      by: null
    },
    estimated: {
      at: null,
      by: null,
      history: {}
    }
  },
  service: {
    inspection: null,
    repair: null,
    testing: null,
    travel: null,
    distance: null
  }
}