module.exports = {
  name: null,
  operators: [],
  officialName: null,
  address: null,
  city: null,
  zip: null,
  country: null,
  regNr: null,
  vatNr: null,
  contacts: null
}