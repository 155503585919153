module.exports = {
  moduleName: 'suppliers',
  columns: [
    {
      field: 'name',
      label: 'common.name',
      visible: true
    },
    {
      field: 'operators',
      label: 'operators.pl',
      visible: true
    },
    {
      field: 'officialName',
      label: 'common.official_name',
      visible: true
    },
    {
      field: 'address',
      label: 'common.address',
      visible: false
    },
    {
      field: 'city',
      label: 'common.city',
      visible: true
    },
    {
      field: 'zip',
      label: 'common.zip',
      visible: false
    },
    {
      field: 'country',
      label: 'common.country',
      visible: true
    },
    {
      field: 'regNr',
      label: 'common.regNr',
      visible: false
    },
    {
      field: 'vatNr',
      label: 'common.vatNr',
      visible: false
    },
    {
      field: 'contacts',
      label: 'common.contacts',
      visible: false
    },
    {
      field: 'created_at',
      label: 'common.created_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'created_by',
      label: 'common.created_by',
      visible: false
    },
    {
      field: 'updated_at',
      label: 'common.updated_at',
      timestamp: true,
      visible: false
    },
    {
      field: 'updated_by',
      label: 'common.updated_by',
      visible: false
    }
  ],
  where: {},
  orderBy: 'name,asc'
}