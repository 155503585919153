import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'

import defaultStore from '@/store/index'

import customers from '@/modules/customers/store'
import depots from '@/modules/depots/store'
import failureNodes from '@/modules/failureNodes/store'
import failureParts from '@/modules/failureParts/store'
import failureSupplies from '@/modules/failureSupplies/store'
import failures from '@/modules/failures/store'
import locales from '@/modules/locales/store'
import mails from '@/modules/mails/store'
import operators from '@/modules/operators/store'
import operatorOverview from '@/modules/operatorOverview'
import parts from '@/modules/parts/store'
import ramsNodes from '@/modules/ramsNodes/store'
import suggestions from '@/modules/suggestions/store'
import suppliers from '@/modules/suppliers/store'
import users from '@/modules/users/store'
import vehicleTypes from '@/modules/vehicleTypes/store'
import vehicles from '@/modules/vehicles/store'

import auth from '@/modules/auth'
import currentDoc from '@/modules/currentDoc'
import dashboard from '@/modules/dashboard'
import user from '@/modules/user'


let modules = [
customers,
depots,
failureNodes,
failureParts,
failureSupplies,
failures,
locales,
mails,
operators,
parts,
ramsNodes,
suggestions,
suppliers,
users,
vehicleTypes,
vehicles
]

let firestoreModules = _.concat(modules, [user])

firestoreModules = _.map(firestoreModules, module => _.merge({}, defaultStore, module))

const easyFirestore = VuexEasyFirestore(
  firestoreModules,
  {
    logging: true,
    preventInitialDocInsertion: true
  }
)

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [easyFirestore],
  modules: {
    auth,
    user,
    currentDoc,
    operatorOverview
  }
})

window.store = store

export {
  modules
}

export default store
