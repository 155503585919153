const config = require('./config')

const actions = require('./actions')
const getters = require('./getters')
const mutations = require('./mutations')
const state = require('./state')

module.exports = {
  ...config,
  actions,
  getters,
  mutations,
  state
}
