<template>
  <a-field
    :field="field"
    :label="label"
    :type="fieldType"
  >
    <b-select
      :value="computedValue"
      :name="field"
      v-bind="$attrs"
      :class="{ [foldedBackgroundClass]: true }"
      @input="update"
    >
      <option
        v-for="option in optionsArray"
        :key="computedOptionValue(option)"
        :value="option"
      >
        {{ computedOptionLabel(option) }}
      </option>
    </b-select>
  </a-field>
</template>

<script>

import Mixin from './Mixin'
import OptionsMixin from './OptionsMixin'
import AField from './AField'

export default {
  components: { AField },
  mixins: [Mixin, OptionsMixin],
  props: {
    foldedBackgroundClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedValue () {
      if (this.value === null) {
      } else if (_.isObject(this.value)) {
        const found = _.find(this.optionsArray, { [this.optionKey]: this.value[this.optionKey] })
        return found
      } else {
        return this.value
      }
    }
  },
  methods: {
    computedOptionValue (option) {
      // COMPAT
      if (option === null) {
        return null
      }
      return _.isString(this.options) ? option[this.optionKey] : (_.isObject(option) ? option[this.optionKey] : option)
    },
    computedOptionLabel (option) {
      if (_.isObject(option)) { // options modulu jsou vzdy primitivni hodnoty
        return _.isFunction(this.optionLabel) ? this.optionLabel(option) : option[this.optionLabel]
      } else if (this.labels) { // moznost dodat popisky jako prop
        return this.labels[option] || option
      } else { // z modulu currentDoc nebo ManualDoc
        return option ? this.$t(`${this.module}.options.${this.field}.${_.snakeCase(option)}`) : option
      }
      // return option
    }
  }
}
</script>
