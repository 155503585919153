const { role } = require('./options')

module.exports = [
  { field: 'name' },
  { field: 'email', label: 'common.email' },
  { field: 'locale', default: 'en' },
  { field: 'phone', label: 'common.phone' },
  { field: 'role', default: 'Reader', options: role },
  { field: 'customer', label: 'customers.sg' },
  { field: 'recentFailures', default: [] }
]
