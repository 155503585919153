const helpers = require('../helpers')

module.exports = {
  MERGE_WHERE (state, payload) {
    helpers.mergeWhereArray(state.sync.where, payload)
  },
  UPDATE_FETCH_IDENTIFIER (state, payload) {
    state.fetchIdentifier = payload
  },
  FETCHING_DONE (state, payload) {
    const { identifier, done } = payload
    let newObj = {}
    newObj[identifier] = done
    state.fetchingDone = { ...state.fetchingDone, ...newObj }
  }
}
