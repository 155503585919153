const _ = require('lodash')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')


module.exports = {
  firestorePath: 'suggestions',
  moduleName: 'suggestions',
  state: {
    moduleName: 'suggestions',
    props: [],
    list: null,
    form: null
  },
  getters: {},
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
