import Vue from 'vue'
import Router from 'vue-router'
import Settings from '@/components/Layout/Settings'

Vue.use(Router)

const routes = [
{
  name: 'operatorOverview',
  path: '/operatorOverview/:id',
  components: {
    default: () => import('@/views/OperatorOverview'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'customers',
  path: '/customers',
  components: {
    default: () => import('@/views/Customers'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'customer',
  path: '/customer/:id',
  components: {
    default: () => import('@/views/Customer'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'depots',
  path: '/depots',
  components: {
    default: () => import('@/views/Depots'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'depot',
  path: '/depot/:id',
  components: {
    default: () => import('@/views/Depot'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'failureNodes',
  path: '/failureNodes',
  components: {
    default: () => import('@/views/FailureNodes'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'failureNode',
  path: '/failureNode/:id',
  components: {
    default: () => import('@/views/FailureNode'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'failureParts',
  path: '/failureParts',
  components: {
    default: () => import('@/views/FailureParts'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'failurePart',
  path: '/failurePart/:id',
  components: {
    default: () => import('@/views/FailurePart'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'failureSupplies',
  path: '/failureSupplies',
  components: {
    default: () => import('@/views/FailureSupplies'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'failureSupplie',
  path: '/failureSupplie/:id',
  components: {
    default: () => import('@/views/FailureSupplie'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'failures',
  path: '/failures',
  components: {
    default: () => import('@/views/Failures'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'failure',
  path: '/failure/:id',
  components: {
    default: () => import('@/views/Failure'),
tools: () => import('@/views/Failure/Tools'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'operators',
  path: '/operators',
  components: {
    default: () => import('@/views/Operators'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'operator',
  path: '/operator/:id',
  components: {
    default: () => import('@/views/Operator'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'parts',
  path: '/parts',
  components: {
    default: () => import('@/views/Parts'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'part',
  path: '/part/:id',
  components: {
    default: () => import('@/views/Part'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'ramsNodes',
  path: '/ramsNodes',
  components: {
    default: () => import('@/views/RamsNodes'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'ramsNode',
  path: '/ramsNode/:id',
  components: {
    default: () => import('@/views/RamsNode'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'suppliers',
  path: '/suppliers',
  components: {
    default: () => import('@/views/Suppliers'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'supplier',
  path: '/supplier/:id',
  components: {
    default: () => import('@/views/Supplier'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'users',
  path: '/users',
  components: {
    default: () => import('@/views/Users'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'user',
  path: '/user/:id',
  components: {
    default: () => import('@/views/User'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'vehicleTypes',
  path: '/vehicleTypes',
  components: {
    default: () => import('@/views/VehicleTypes'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'vehicleType',
  path: '/vehicleType/:id',
  components: {
    default: () => import('@/views/VehicleType'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{
  name: 'vehicles',
  path: '/vehicles',
  components: {
    default: () => import('@/views/Vehicles'),
    settings: {
      extends: Settings
    }
  },
  props: true
},
{
  name: 'vehicle',
  path: '/vehicle/:id',
  components: {
    default: () => import('@/views/Vehicle'),
    settings: {
      extends: Settings
    }
  },
    props: true
 },
{ path: '/', redirect: '/failures' },
{ name: 'imports', path: '/imports', component: () => import('@/views/Csv') },
{ name: 'dashboard', path: '/dashboard', component: () => import('@/views/Dashboard') },
{ name: 'locales', path: '/locales', component: () => import('@/views/Locales') },
{ name: 'searchLocales', path: '/searchLocales', component: () => import('@/views/SearchLocales') }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

window.router = router

export default router
