const _ = require('lodash')
const helpers = require('../helpers')

module.exports = {
  routeSettings: (state, getters, rootState, rootGetters) => {
    if (state.moduleName && rootGetters['user/routes']) {
      return rootState.user.data.routes[state.moduleName] || null
    }
    return null
  },
  displayedFields: (state, getters, rootState, rootGetters) => {
    const { routeSettings } = getters
    if (routeSettings) {
      const { columns } = routeSettings
      return _.map(_.filter(columns, 'visible'), 'field')
    }
    return []
  },
  orderOptions: (state, getters, rootState, rootGetters) => {
    return _.assign({'created_at,desc': 'Last created'}, state.list.orderOptions)
  },
  data: (state, getters) => state.data,
  items: (state, getters, rootState, rootGetters) => {
    let order = ['created_at', 'desc']
    if (getters.routeSettings && getters.routeSettings.orderBy) {
      order = getters.routeSettings.orderBy.split(',')
    }
    return _.values(_.orderBy(state.data, order[0], order[1]))
  },
  itemsToExport: (state, getters, rootState, rootGetters ) => {
    const { displayedItems, routeSettings } = getters
    const { columns } = routeSettings
    const columnsToPick = _.filter(columns, column => column.visible)
    const data = _.map(displayedItems, item => {
      let newItem = {}
      _.forEach(columnsToPick, column => {
        let rawValue = _.get(item, column.field) || ''
        newItem[column.field] = column.timestamp && rawValue ? moment(_.isFunction(rawValue.toDate) ? rawValue.toDate() : rawValue).format('LL') : rawValue
      })
      return newItem
    })
    return data
  },
  watched: (state, getters, rootState) => {
    const { auth } = rootState
    if (auth.data && auth.data.uid) {
      return _.filter(getters.items, i => i.watches && i.watches.includes(auth.data.uid))
    }
    return []
  },
  filteredItems: (state, getters, rootState, rootGetters) => {
    const { routeSettings, items } = getters
    if (routeSettings && items) {
      let filtered = _.clone(items)
      _.forEach(routeSettings.where, (value, key) => {
        filtered = helpers.filterRecursive(value, key, filtered, '', state.moduleName)
      })
      return filtered
    }
    return items || []
  },
  displayedItems: (state, getters, rootState, rootGetters) => {
    const { routeSettings, displayedFields, filteredItems } = getters
    if (routeSettings && displayedFields) {
      const { search } = routeSettings
      if (displayedFields.length && filteredItems.length) {
        return helpers.searchInFields(search, displayedFields, filteredItems)
      }
    }
    return filteredItems
  },
  props: (state, getters) => state.props,
  fields: (state, getters) => _.mapKeys(state.props, 'field'),
  labels: (state, getters) => _.mapValues(_.keyBy(getters['props'], 'field'), 'label'),

  isAdmin: (state, getters, rootState, rootGetters) => rootGetters['user/role'] === 'admin',
  isTechnician: (state, getters, rootState, rootGetters) => rootGetters['user/role'] === 'technician',
  isReader: (state, getters, rootState, rootGetters) => rootGetters['user/role'] === 'reader',
  isCustomer: (state, getters, rootState, rootGetters) => rootGetters['user/role'] === 'customer',

  belongsToCustomer: (state, getters, rootState, rootGetters) => rootGetters['user/role'] === 'customer',

  canRead: (state, getters, rootState, rootGetters) => getters.isAdmin,
  canCreate: (state, getters, rootState, rootGetters) => getters.isAdmin,
  canUpdate: (state, getters, rootState, rootGetters) => getters.isAdmin,
  canDelete: (state, getters, rootState, rootGetters) => getters.isAdmin,
  fetchIdentifier: (state, getters, rootState, rootGetters) => state.fetchIdentifier,
  fetchingDone: (state, getters, rootState, rootGetters) => state.fetchingDone,
  fetchedAll: (state, getters, rootState, rootGetters) => {
    const { fetchIdentifier, fetchingDone } = getters
    return fetchingDone[fetchIdentifier]
  },
  sync: (state, getters, rootState, rootGetters) => state.sync
}
