<template>
  <a-field
    :field="field"
    :label="label"
  >
    <b-taginput
      :data="availableOptions"
      :field="optionLabel"
      :value="value"
      :name="optionLabel"
      open-on-focus
      autocomplete
      @typing="onTyping"
      @input="update"
    >
      <template #default="props">
        {{ computedLabel(props.option) }}
      </template>
    </b-taginput>
  </a-field>
</template>

<script>

import _ from 'lodash'

import Mixin from './Mixin'
import AutocompleteMixin from './AutocompleteMixin'
import AField from './AField'

export default {
  components: {
    AField
  },
  mixins: [Mixin, AutocompleteMixin],
  computed: {
    computedOptions () {
      return _.map(this.optionsArray, item => _.pick(item, [this.optionKey, this.optionLabel]))
    },
    remainingOptions () {
      return _.filter(this.computedOptions, item => !_.find(this.value, { id: item.id }))
    }
  },
  methods: {
    computedLabel(option) {
      if(typeof this.options === 'string') {
        return option[this.optionLabel]
      }
      return (_.isArray(this.options) || _.isObject(this.options)) ? option[optionLabel] : this.$t(`${this.module}.options.${this.field}.${_.snakeCase(option)}`)
    }
    /* add (item) {
      let newValues = _.clone(this.value)
      const newItem =
      newValues.push(newItem)
      console.log('updating', newValues)
      this.update(newValues)
    },
    remove (item) {
      const newValues = _.remove(this.value, { id: item.id })
      console.log('updating', newValues)
      this.update(newValues)
    } */
  }
}
</script>
