const flatten = require('flat')
const _ = require('lodash')

var compareObjects = function (oldObj, newObj) {
    let diff = {}
    const existingKeys = _.keys(flatten(oldObj))
    _.forEach(existingKeys, key => {
      oldValue = _.get(oldObj, key)
      newValue = _.get(newObj, key)
      if((!_.isObject(newValue) && !_.isUndefined(newValue)) && (oldValue !== newValue)) {
        _.set(diff, key, newValue)
      }
    })
    return diff
}

var getAllSubstrings = function (str) {

  var i, j, result = [];

  if(!str || !str.length) {
    return result
  }

  for (i = 0; i < str.length; i++) {
      for (j = i + 1; j < str.length + 1; j++) {
          result.push(str.slice(i, j));
      }
  }
  return result;
}

var mergeRoutesCustomizer = function(objValue,srcValue,key,object,source,stack){
  if (key === 'columns') {
    // debugger
  }
  // DEV
/*  if (key === 'orderBy' && _.isArray(source.orderBy) && source.orderBy.length === 0) {
    return object.orderBy
  }
*/
  if (key === 'tooltips') {
    return object.tooltips
  }


  if (_.isArray(objValue) && !srcValue) {
    console.warn('returning template value', objValue)
    return objValue
  }

  /*if (_.isArray(objValue) && (!srcValue.length || _.isString(srcValue[0]))) {
    console.warn('key', key)
    debugger
  }*/

  if (_.isArray(objValue)) {
    if((objValue[0] && _.isString(objValue[0])) || (srcValue[0] && _.isString(srcValue[0]))) {
      return srcValue
    } else {
      let unionByKey = null
      const comparableKeys = ['id', 'value', 'field']
      comparableKeys.some((key) => {
        if (_.has(objValue[0], key)) {
          unionByKey = key
          return true
        }
      })
      // console.log(srcValue, objValue)
      if(key === 'columns') {
        _.forEach(srcValue, (newColumn, index) => {
          const found = _.find(objValue, column => column[unionByKey] === newColumn[unionByKey])
          if(found) {
              srcValue[index].label = found.label
            if(!_.isString(found.label)) {
              console.error(found.label, ' is not a string')
            }
          }
        })
      }

      const merged = _.unionBy(srcValue, objValue, unionByKey)
      const filtered = _.filter(merged, mixedItem => {
        // console.log(unionByKey, mixedItem[unionByKey])
        // return only thos, that exist (by key - id, value or field) in defaultSettings
        return _.find(objValue, actualItem => actualItem[unionByKey] === mixedItem[unionByKey])
      })
      if(key === 'columns') {
      // console.log(object.moduleName, filtered)
      }
      return filtered
    }
  }
}

const makeFailureRef = function (failure) {
  return _.assign({}, _.omit(failure, [
    'created_at',
    'created_by',
    'updated_at',
    'updated_by',
    'notes',
    'contacts',
    'team',
    'order_number_array',
    'number_array'

  ]), {
    customer: _.pick(failure.customer, ['id', 'name']),
    operator: _.pick(failure.operator, ['id', 'name']),
    vehicle: failure.vehicle ? _.pick(failure.vehicle, ['id', 'number', 'vehicleType.id', 'vehicleType.name']) : null
  })
}

module.exports = {
  compareObjects,
  getAllSubstrings,
  mergeRoutesCustomizer,
  makeFailureRef
}
