const _ = require('lodash')
const moment = require('moment')
const helpers = require('../helpers')

const receivedDate = function(row) {
  const received_at = row.created_at || null
  return received_at ? moment(received_at).format('LL') : ''
}

const repairedDate = function(row) {
  const repaired_at = row.progress.repaired.at || row.progress.completed.at || row.updated_at
  return repaired_at ? moment(repaired_at).format('LL') : ''
}

module.exports = {
  moduleName: 'operatorOverview',
  namespaced: true,
  state: {
    form: {
      from: null,
      to: null
    },
    columns: [
      { field: 'failure.name', label: 'failures.ref.name' },
      { field: 'part.name', label: 'parts.ref.name' },
      { field: 'number', label: 'common.number' },
      { field: 'name', label: 'common.description' },
      { field: 'resolution', label: 'common.resolution' },
      { field: 'received', label: 'failureParts.options.state.received' },
      { field: 'repaired', label: 'failureParts.options.state.repaired' },
      { field: 'state', label: 'common.state' },
      { field: 'failure.operator.name', label: 'operators.ref.name' }
    ]
  },
  actions: {
    exportToCsv ({ dispatch, rootGetters, getters, $app }, {
      filename = 'export'
    } = {}) {
      const { itemsToExport, routeSettings } = getters
      const { columns } = routeSettings
      const headers = _.map(_.filter(columns, column => column.visible), item => app.$t(item.label))

      const options = {
        filename,
        fieldSeparator: ';',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        headers
      }
      const { ExportToCsv } = require('export-to-csv')
      const csvExporter = new ExportToCsv(options)

      csvExporter.generateCsv(itemsToExport)
    }
  },
  getters: {
    routeSettings: (state, getters, rootState, rootGetters) => {
      return {
        columns: _.map(state.columns, item => {
          item.visible = true
          return item
        })
      }
    },
    rootItems: (state, getters, rootState, rootGetters) => {
      const { route } = rootState
      return _.filter(
        _.concat(rootGetters['failureParts/rootItems'], rootGetters['failureNodes/rootItems']),
        item => item.failure.operator.id === route.params.id && item.failure.progress.reported.at
      )
    },
    settings: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/routes'].operatorOverview
    },
    items: (state, getters, rootState, rootGetters) => {
      let { rootItems, settings } = getters
      const from = settings.from ? moment(_.isFunction(settings.from.toDate) ? settings.from.toDate() : settings.from) : null
      const to = settings.to ? moment(_.isFunction(settings.to.toDate) ? settings.to.toDate() : settings.to) : null
      const filtered = _.filter(rootItems, item => {
        let reported_at = moment(item.failure.progress.reported.at.toDate())
        const isFrom = (from ? reported_at.isAfter(from) : true)
        const isTo = (to ? reported_at.isBefore(to) : true)
        return isFrom && isTo
      })

      return _.map(filtered, item => {
        item.received = receivedDate(item)
        item.repaired = repairedDate(item)
        return item
      })
    },
    itemsToExport: (state, getters, rootState, rootGetters ) => {
    const { displayedItems, routeSettings } = getters
    const { columns } = routeSettings
    const columnsToPick = _.filter(columns, column => column.visible)
    const data = _.map(displayedItems, item => {
      let newItem = {}
      _.forEach(columnsToPick, column => {
        let rawValue = _.get(item, column.field) || ''
        newItem[column.field] = column.timestamp && rawValue ? moment(_.isFunction(rawValue.toDate) ? rawValue.toDate() : rawValue).format('LL') : rawValue
      })
      return newItem
    })
    return data
  },
    displayedItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      return _.orderBy(items, ['failure.progress.reported.at']).sort((a, b) => {
        return a.state === 'repairing' ? -1 : 0
      })
    },
    chartItems: (state, getters, rootState, rootGetters) => {
      const { items } = getters
      return _.orderBy(_.map(items, item => ({
        type: item.part.name,
        month: moment(item.failure.progress.reported.at.toDate()).format('YYYYMM')
      })), ['month'])
    },
    startDate: (state, getters, rootState, rootGetters) => {
      const { settings, items } = getters
      const { from } = settings
      if(from) {
        return from.toDate()
      } else {
        return items.length ? items[0].failure.progress.reported.at.toDate() : new Date()
      }
    },
    endDate: (state, getters, rootState, rootGetters) => {
      const { settings, items } = getters
      const { to } = settings
      if(to) {
        return to.toDate()
      } else {
        return items.length ? items[items.length - 1].failure.progress.reported.at.toDate() : new Date()
      }
    },
    chartMonths: (state, getters, rootState, rootGetters) => {
      const { startDate, endDate } = getters
      let months = []
      let start = moment(startDate)
      let end = moment(endDate)

      while (end > start || start.format('M') === end.format('M')) {
         months.push(start.format('YYYYMM'))
         start.add(1,'month')
      }

      return months.sort()
    },
    stackedBarData: (state, getters, rootState, rootGetters) => {
      const { chartMonths, chartTypes, chartItems } = getters
      const chartHeaders = [_.concat(['Part type'], chartTypes)]
      const chartData = _.map(chartMonths, month => {
          let item = { month }
          const monthTypeCounts = _.map(chartTypes, type => _.filter(chartItems, { month, type }).length)
          return _.concat([month], monthTypeCounts)
      })
      return _.concat(chartHeaders, chartData)
    },
    chartTypes: (state, getters, rootState, rootGetters) => {
      const { chartItems } = getters
      return _.map(_.uniqBy(chartItems, 'type'), 'type')
    },
    chartTypesWithCount: (state, getters, rootState, rootGetters) => {
      const { chartTypes, chartItems } = getters
      const chartHeaders = [['Part type', 'Failures count']]
      const chartData = _.map(chartTypes, type => [
        type,
        _.filter(chartItems, { type }).length
      ])
      return _.concat(chartHeaders, chartData)
    }
  }
}
