<template>
  <b-field
    :label="computedLabel"
    :type="type"
  >
    <template
      v-if="tooltip"
      #label
    >
      {{ computedLabel }}
      <b-tooltip
        :label="tooltip"
        type="is-dark"
        multilined
      >
        <b-icon
          size="is-small"
          icon="help-circle-outline"
        />
      </b-tooltip>
    </template>
    <slot />
  </b-field>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'is-default'
    }
  },
  computed: {
    ...mapGetters({
      fields: 'currentDoc/fields'
    }),
    computedLabel () {
      if (this.label) {
        return this.label
      } else if (this.label === null) {
        return ''
      } else {
        const prop = this.fields[this.$parent.field]
        return this.$t(prop && prop.label ? prop.label : `${this.module}.props.${this.$parent.field}`)
      }
    },
    tooltip () {
      return '' // TODO4 return this.field ? _.get(this.config.tooltips, this.field) : ''
    }
  },
  methods: {
    hasError () {
      return false
    }
  }
}
</script>
