const firebase = require('firebase')
// const region = 'europe-west1'

async function getFailureSupplyNumber () {
  var getFailureSupplyNumber = firebase.functions().httpsCallable('callables-getFailureSupplyNumber')
  try {
    const result = await getFailureSupplyNumber()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

async function getFailureNumber () {
  var getFailureNumber = firebase.functions().httpsCallable('callables-getFailureNumber')
  try {
    const result = await getFailureNumber()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

async function getUser () {
  var getUser = firebase.functions().httpsCallable('callables-getUser')
  try {
    const result = await getUser()                                                                      ``````                      
    console.log(result)
    return result
  } catch (e) {
    console.log(e)
  }
}

async function setUserRoutesDefaults () {
  var setUserRoutesDefaults = firebase.functions().httpsCallable('callables-setUserRoutesDefaults')
  try {
    const result = await setUserRoutesDefaults()
    console.log(result.data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

async function setDefaultColumns (route) {
  var setDefaultColumns = firebase.functions().httpsCallable('callables-setDefaultColumns')
  try {
    const result = await setDefaultColumns({ route })
    console.log(result.data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

module.exports = {
  getFailureNumber,
  getFailureSupplyNumber,
  setUserRoutesDefaults,
  setDefaultColumns,
  getUser
}
