module.exports = {
  part: {},
  number: null,
  number_array: null,
  name: null,
  replacement: {
    number: null
  },
  failure: {
    progress: {
      reported: {
        at: null
      }
    }
  },
  decomposition: {},
  operating_since: null,
  daysInOperation: null
}