const _ = require('lodash')
const props = require('./props')
const defaultValues = require('./defaultValues')
const convertTimestamps = require('./timestamps')

module.exports = {
  firestorePath: 'vehicles',
  moduleName: 'vehicles',
  state: {
    moduleName: 'vehicles',
    props,
    rules: {
      read: ['belongsToCustomer']
    },
    list: {
      orderBy: 'id,asc',
      orderOptions: {
        'number,asc': 'Vehicle number'
      },
      initialFilters: {
        operator: null
      }
    }
  },
  getters: {
    canRead: (state, getters, rootState, rootGetters) => true,
    currentVehicleId: (state, getters, rootState, rootGetters) => {
      const { route } = rootState
      if (route.name === 'vehicle') {
        let vehicle = rootGetters['vehicles/items'].find(item => item.id === route.params.id)
        return vehicle ? vehicle.id : null
      }
      return null
    },
    currentVehicleNodes: (state, getters, rootState, rootGetters) => {
      const { currentVehicleId } = getters
      if (currentVehicleId) {
        let vehicleNodes = rootGetters['vehicleNodes/items'].find(item => item.id === currentVehicleId)
        return vehicleNodes ? vehicleNodes.items : null
      }
      return null
    }
  },
  serverChange: {
    convertTimestamps
  },
  sync: {
    defaultValues
  }
}
