module.exports = [
  { field: 'name', label: 'common.name' },
  { field: 'vehicleTypes', label: 'vehicleTypes.pl', default: [] },
  { field: 'depots', label: 'depots.pl', default: [] },
  { field: 'contacts', label: 'common.contacts', default: [] },

  { field: 'officialName', label: 'common.official_name' },
  { field: 'address', label: 'common.address' },
  { field: 'city', label: 'common.city' },
  { field: 'zip', label: 'common.zip' },
  { field: 'country', label: 'common.country' },
  { field: 'regNr', label: 'common.regNr' },
  { field: 'vatNr', label: 'common.vatNr' }

]
