const { state, location, type, recognized } = require('./options')

module.exports = [
  { field: 'name' },
  { field: 'description' },
  { field: 'resolution', label: 'common.resolution' },
  { field: 'odometer', default: 0 },
  { field: 'type', default: 'guarantee', options: type },
  { field: 'recognized', options: recognized },
  { field: 'state', label: 'common.state', default: 'draft', options: state },
  { field: 'location', options: location },
  { field: 'number', label: 'common.number' },
  { field: 'number_array', default: [], label: false }, // array of possible substrings
  { field: 'contract_number' },
  { field: 'notified' },
  { field: 'order_number' },
  { field: 'order_number_array', default: [], label: false }, // array of possible substrings

  { field: 'notes', label: 'common.notes', default: {} },
  { field: 'contacts', label: 'common.contacts', default: {} },

  { field: 'vehicle', label: 'vehicles.sg' },
  { field: 'operator', label: 'operators.sg' },
  { field: 'customer', label: 'customers.sg' },
  { field: 'depot', label: 'depots.sg' },

  { field: 'progress.reported.at', timestamp: true },
  { field: 'progress.reported.by' },
  { field: 'progress.serviced.at', timestamp: true },
  { field: 'progress.serviced.by' },
  { field: 'progress.completed.at', timestamp: true },
  { field: 'progress.completed.by' },
  { field: 'progress.estimated.at', timestamp: true },
  { field: 'progress.estimated.by' },
  { field: 'progress.estimated.history', default: {} },

  { field: 'service.inspection', default: 0 },
  { field: 'service.repair', default: 0 },
  { field: 'service.testing', default: 0 },
  { field: 'service.travel', default: 0 },
  { field: 'service.distance' }
]
