const _ = require('lodash')
const Vue = require('vue')
const helpers = require('../helpers')

module.exports = {
  moduleName: 'dashboard',
  namespaced: true,
  state: {
    form: {
      estimated: {
        upcomingLimit: 30
      },
      reported: {}
    }
  },
  actions: {
    setUser ({ commit, dispatch }, currentUser) {
      commit('SET_USER', currentUser)
    }
  },
  mutations: {
    SET_USER (state, currentUser) {
      state.data = currentUser
    }
  }
}
