const moment = require('moment')
const _ = require('lodash')
const { isPlainObject, isAnyObject } = require('is-what/dist/index.js')

var keyNameToOperator = function(keyName) {
  if(_.endsWith(keyName, '_array')) {
    return 'array-contains'
  }

  if(_.endsWith(keyName, '_gte')) {
    return '>='
  }
  return '=='
}

var rawKeyFn = function(keyName) {
  if(_.endsWith(keyName, '_gte')) {
    return keyName.slice(0, -4)
  }
  return keyName
}


var mergeWhereArray = function (whereArray, value) {
  const found = _.find(whereArray, { 0: value[0] })
  if (found) {
    found[2] = value[2]
  } else {
    if (_.isArray(value[2])) {
      whereArray.push([value[0], 'in', value[2]])
    } else if (_.endsWith(value[0], '_array')) {
      whereArray.push([value[0], 'array-contains', value[2]])
    } else {
      whereArray.push([value[0], '==', value[2]])
    }
  }
}

var filterRecursive = function (value, key, filtered, parentsString, moduleName) {
  const rawKey = rawKeyFn(key)
  const operator = keyNameToOperator(key)
  const actualKey = parentsString ? `${parentsString}.${rawKey}` : rawKey
  if (value) {
    if (_.isString(value)) {
      if (_.endsWith(key, '_array')) {
        // console.warn('filtering in_array', key)
        filtered = _.filter(filtered, item => {
          const actualValue = _.get(item, actualKey)
          return actualValue && _.includes(actualValue, value)
        })
      } else {
        filtered = _.filter(filtered, item => {
          const actualValue = _.get(item, actualKey)
          return actualValue && actualValue === value
        })
      }
      return filtered
    } else if (_.isArray(value) && value.length) {
      filtered = _.filter(filtered, item => {
        const actualValue = _.get(item, actualKey)
        return actualValue && _.includes(value, actualValue)
      })
      return filtered
    } else if (_.isObject(value)) {
      if (_.has(value, 'id')) {
        if(value.id) {
          filtered = _.filter(filtered, item => {
            const actualValue = _.get(item, actualKey)
            return actualValue && actualValue.id === value.id
          })
        }
        return filtered
      } else if (value instanceof firebase.firestore.Timestamp) {
        const filteredDate = moment(value.toDate())
        // return filtered
        filtered = _.filter(filtered, item => {
          const actualValue = _.get(item, actualKey)
          const actualDate = actualValue ? moment(actualValue.toDate()) : null
          if(!actualValue || !actualDate) {
            return false
          }
          switch(operator) {
            case '>=':
              // console.log(actualDate, filteredDate)
              return actualDate.isSameOrAfter(filteredDate, 'day')

          }
        })
        return filtered
      } else {
        _.forEach(value, (nestedValue, nestedKey) => {
          // console.warn(`nestedKey ${nestedKey}`, nestedValue)
          filtered = filterRecursive(nestedValue, nestedKey, filtered, actualKey)
        })
        return filtered
      }
    }
  } else {
    return filtered
  }
}

var buildTreeArray = function (sourceArray, outputArray, value = null, level = 0) {
  let childNodes = _.filter(sourceArray, item => {
    return (item.parent !== null && value !== null) ? item.parent.id === value.id : item.parent === null && value === null
  })

  level++
  _.forEach(childNodes, child => {
    const valueIndex = _.indexOf(outputArray, value)
    child.level = level
    outputArray.splice(valueIndex + 1, 0, child)
    buildTreeArray(sourceArray, outputArray, child, level)
  })

  return outputArray
}

var buildTree = function (sourceArray, outputArray, value) {
  let childNodes = _.filter(sourceArray, item => {
    return item.parent.id === value.id
  })

  _.forEach(childNodes, child => {
    const valueIndex = _.indexOf(outputArray, value)
    child.level = level
    outputArray.splice(valueIndex + 1, 0, child)
    buildTreeArray(sourceArray, outputArray, child, level)
  })

  return outputArray
}


// presunout do filterresursive, nebude treba prepisovat podminky
var filterToWhereCondition = function (value, key, where, parentsString) {
  if (value) {
    const rawKey = rawKeyFn(key)
    const actualKey = parentsString ? `${parentsString}.${rawKey}` : rawKey
    // console.log({ key })
    if (_.isString(value)) {
        where.push([actualKey, keyNameToOperator(key), value])
      return where
    } else if (_.isArray(value) && value.length) {
      where.push([actualKey, 'in', value])
      return where
    } else if (_.isObject(value)) {
      if (value.id) {
        where.push([`${actualKey}.id`, '==', value.id])
        return where
      } else if (value instanceof firebase.firestore.Timestamp) {
        // console.log('is date', value)
        where.push([actualKey, keyNameToOperator(key), value.toDate()])
        return where
      } else {
        _.forEach(value, (nestedValue, nestedKey) => where = filterToWhereCondition(nestedValue, nestedKey, where, actualKey))
        return where
      }
    }
  } else {
    return where
  }
}

var filtersToWhereConditions = function (filters) {
  let where = []
  _.forEach(filters, (value, key) => where = filterToWhereCondition(value, key, where, ''))
  return where
}

/*var stringifyParams = function (params = []) {
  return params.map(param => {
    if (_.isObject(param) && !_.isPlainObject(param) && !_.isArray(param)) {
      const constructorString = param.constructor && param.constructor.name ? param.constructor.name.toString() : ''
      const id = param.id || ''
      return constructorString + id
    }
    const paramString = param.toString()
    return paramString
  }).join()
}

var createFetchIdentifier = function (sync = {}) {
  let identifier = ''
  if ('where' in sync) {
    identifier += '[where]' + sync.where.map(where => stringifyParams(where)).join()
  }
  if ('orderBy' in sync) {
    identifier += '[orderBy]' + sync.orderBy
  }
  identifier += '[pathVariables]{}'
  return identifier
}*/

var stringifyParams = function(params) {
  console.log({ params })
  return params.map(param => {
    if (isAnyObject(param) && !isPlainObject(param)) {
      // @ts-ignore
      return String(param.constructor.name) + String(param.id)
    }
    return String(param)
  }).join()
}

var createFetchIdentifier = function(whereOrderBy) {
  let identifier = ''
  if ('where' in whereOrderBy) {
    identifier += '[where]' + whereOrderBy.where.map(where => stringifyParams(where)).join()
  }
  if ('orderBy' in whereOrderBy) {
    identifier += '[orderBy]' + whereOrderBy.orderBy
  }
  if ('pathVariables' in whereOrderBy) {
    delete whereOrderBy.pathVariables.where
    delete whereOrderBy.pathVariables.orderBy
    identifier += '[pathVariables]' + JSON.stringify(whereOrderBy.pathVariables)
  }
  return identifier
}


var searchInFields = function (search, fields, items) {
  return search ? _.filter(items, option => _.some(fields, field => {
    const value = _.get(option, field)
    if (!value) {
      console.warn(`Field ${field} does not exist in`, option)
      return false
    } else {
      return value
        .toString()
        .toLowerCase()
        .indexOf(search.toLowerCase()) >= 0
    }
  })) : items
}

var fuzzyFilter = function (search, text) {
  text = text ? text.toString().toLowerCase() : ''
  search = search ? search.toString().toLowerCase() : ''

  var searchTextIndex = 0
  for (var index = 0; index < text.length; index++) {
    if (text[index] === search[searchTextIndex]) {
      searchTextIndex += 1
    }
  }
  return searchTextIndex === search.length
}

var findPropertyInVueTree = function(component, property) {
  let found = _.get(component.$props, property)
  if(found) {
    return found
  }
  if(component.$parent && component.$parent.$props) {
      found = _.get(component.$parent.$props, property)
      if(found) {
      return found
    }
    return findPropertyInVueTree(component.$parent, property)
  }
  return null
}

var findPropertyInCollection = function(collection, property, key = 'field') {
  // console.log(collection, property)
  let found = _.get(collection, property )
  // console.warn('found', found)
  if(found) {
    return found
  }
  const propertyParts = _.split(property, '.')
  const newKeyParts = _.take(propertyParts, propertyParts.length - 1)
  // console.warn({ propertyParts })
  // console.warn({ newKeyParts })
  if(newKeyParts.length) {
    const newKey = _.join(newKeyParts, '.')
    // console.warn({ newKey })
    return findPropertyInCollection(collection, newKey, key)
  }
  return null
}

var aPromise = function() {
  const m = {
    resolve: null,
    reject: null,
    isFulfilled: false,
    isRejected: false,
    isPending: true
  }

  const p = new Promise((resolve, reject) => {
    m.resolve = resolve
    m.reject = reject
  })

  Object.assign(p, m)

  p
    .then(() => (p.isFulfilled = true))
    .catch(() => (p.isRejected = true))
    .finally(() => (p.isPending = false))

  return p
}

module.exports = {
  mergeWhereArray,
  filterRecursive,
  buildTreeArray,
  filtersToWhereConditions,
  createFetchIdentifier,
  searchInFields,
  fuzzyFilter,
  findPropertyInVueTree,
  findPropertyInCollection,
  aPromise
}
